import React, { useState, useEffect ,useContext } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext"; // Import useAuth
import { GoogleLogin } from '@react-oauth/google';
import { Base_URL } from "../../Components/BaseURL";
import {Routes, Route, useNavigate} from 'react-router-dom';
import {ToastContainer,toast} from 'react-toastify'
import { useWindowSize } from "@uidotdev/usehooks";
import { Divider, List } from "@mui/material"; 
import Home from '../../Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faUser, faLock, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
function UserRegister() {
  const [showPassword, setShowPassword] = useState(false);
  const size = useWindowSize();
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login from context
  const [password, setPassword] = useState("");
  const [regDone, setRegDone] = useState(false)
 const history = useNavigate(); //for dinamically changing the path
 const [data, setData] = useState({
  username: "",
  password: ""
});
useEffect(() => {
  // Apply styles on mount
  document.body.style.overflowX = 'hidden';
  document.body.style.overflowY = 'hidden';

  // Cleanup on unmount
  return () => {
    document.body.style.overflowX = '';
    document.body.style.overflowY = '';
  };
}, []);
useEffect(() => {
  document.title = "stockwiseanalytics.com - Sign In Page"; //dinamically changes the tittle
})
const handleChange = (e) => {
  const value = e.target.value;
  setData({
    ...data,
    [e.target.name]: value
  });
};
 //login function with axios
 const HandleRegister = async (e) => {
   e.preventDefault(); //disables the reload on submission
   localStorage.setItem('unm',data.username);
   const userData = {
    username: data.username,
    password: data.password
  };
   try {
     //check if user has filled all required fields
     if (!data.username || data.username === "" || !data.password || data.password === "") {
      toast.warning("All Fields are Required");
      return;
    }
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
 if (!emailRegex.test(data.username)) {
 toast.error("Error! you have entered invalid email.");
   return;
 }       try {
        // const response = await axios.post(`${Base_URL}/auth/signup`, userData, axiosConfig);
        // console.log(response);

        const response = await axios.post(`${Base_URL}/auth/signup`, userData, {
          withCredentials: true, // ensures that cookies (like JWT tokens) are sent along with requests
          headers: {
            'Content-Type': 'application/json'
          }
        });
   
        // Handle response based on status code
        switch (response.status) {
          case 201:
          case 200:
            const token = response.data.token;
            localStorage.setItem("token", token);
            toast.success("Sign Up Successful, Redirecting...");
            setTimeout(() => navigate("/login"), 3000); // Redirect after 3 seconds
            break;
    
          case 500:
            toast.info(response.data.error || "User with the same username already exists...");
            return Promise.reject(response.data.error)  
            setTimeout(() => navigate("/login"), 3000); // Redirect after 3 seconds
            break;
    
          case 401:
            toast.warning("Incorrect Password...");
            break;
    
          default:
            toast.error("Unexpected response from the server.");
        }
      } catch (error) {
        if (error.response) {
          console.log("Server responded with an error:", error.response);
          const errorMessage = error.response.data.error || "An error occurred";
         // toast.error(errorMessage);
if ( error.response.status==500)
{
  toast.info("User with the same username already exists...");
  setTimeout(() => navigate("/login"), 3000); // Redirect after 3 seconds
}

        } else if (error.request) {
          console.log("Network error:", error.request);
          toast.error("Network error, please try again later.");
        } else {
          console.log("Error:", error.message);
          toast.error("An unexpected error occurred.");
        }
      }
  
  
   } catch (error) {
     //incase of error
     console.error("Error User with the same username already exists: ", error);
     toast.error("Error User with the same username already exists");
   }
 };
 const navigateToLoginPage = () => {
    navigate('/login');
 }
  function handleClick() {
    navigate("/home");
  }

  const handleLoginSuccess = (response) => {
    console.log("Google Login Success:", response);
  
    // Make sure you're sending the actual token (JWT) from response.credential
    fetch(`${Base_URL}/auth/google/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: response.credential }),  // Ensure this is the JWT token
    })
    .then(res => res.json())
    .then(data => {
      console.log("Login Success", data);
      localStorage.setItem('unm', data.user.username);  // Store username
      localStorage.setItem('token', data.token);        // Store JWT token
      localStorage.setItem('userid', data.user.id);     // Store user ID
         login(data.token, data.user.id);
          navigate("/");
      // Handle successful login (e.g., store JWT, redirect)
    })
    .catch(err => {
      console.error('Error during authentication:', err);
    });
  };

  const handleLoginError = () => {
    console.error("Google Login Failed");
  };
  return (
    <>
    <div className="container-fluid pt-0 mb-0 mt-0 bg-black text-white h-100">
      <div className="mt-1" style={{marginRight: '0px'}}> 
        <div className="bg-black row centered-element">
          <div className="bg-black col-xxl-6">
            <video
              className="videoTagsingin"
              autoPlay
              loop
              muted
              style={{ width: "50%", height: "100%", }}
            >
              <source src="/videos/login.mp4" type="video/mp4" />
            </video>
          </div>


          
          <div className="col-xxl-6 form-container d-flex justify-content-between viewport"           
          style={{paddingRight: '0px'}}>
            <div className="input-group mb-0 flex-column align-items-center min-vh-100" 
            style={{ top: '0px', textAlign: 'center', 
            width: '100%',
            background: '#000'
            // background: 'linear-gradient(to bottom right, black, #282727)',background: 'linear-gradient(to bottom, transparent 1%, black 100%)'
             }}>
 
      <div className='p-3'></div>
      <div className='p-2'></div>
              <div className="col-12 justify-content-center align-items-center">
<a href="/"><img className="img-fluid d-md-none" style={{ maxWidth: '45px' ,height:'auto'}} onClick={handleClick} src="/images/SVAlogo.png" alt="Logo" />
            <img className="header-logo d-none d-md-block header-logo" onClick={handleClick}  src="/images/SVAlogoWithName.png" alt="Logo" />
      </a>
              </div>
               <form onSubmit={HandleRegister} className='login-form justify-content-end' style={{width: '100%'}}>
                <div className="input-group mb-15">
                  <div className="error-setup text-nowrap"><p id='err' className='error-message'></p></div>
                </div>
                <div class="container mt-3"></div>
                <div class="LogIn w-100 h-5 text-center text-neutral-300 text-3xl signuptitle">Sign Up</div>
                <div className="SignUptext text-center col-12 m-0 px-0 py-0 text-white">
                <div class="container mt-3" style={{width: '100%'}}>

                <span className="textlight">Sign up to</span>
                  <span className="textdark">&nbsp;access your account</span>
                  <span className="textlight">&nbsp;and<br/></span>
                  <span className="textdark">&nbsp;unlock a world of powerful tools and<br /> insights</span>
                  <span className="textlight">&nbsp;for your stock market journey.</span>
                </div>
            
                </div>
                <div class="p-2"></div>
                <div className='mb-3'>
                <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'>
                <div style={{"color":"rgba(255, 255, 255, 0.49)","textAlign":"left","fontFamily":"'Montserrat-Regular', sans-serif","fontSize":"16px","lineHeight":"31px","fontWeight":"400","position":"relative","alignSelf":"stretch","flex":"1","display":"flex","alignItems":"center","justifyContent":"flex-start"}}>
    <input
      type="email"
      className="emailctrl"
      placeholder="Email Address"
      name="username"
      onChange={handleChange} 
   />
     </div>
</div>
</div>

<div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'>
                <div style={{"color":"rgba(255, 255, 255, 0.49)","textAlign":"left","fontFamily":"'Montserrat-Regular', sans-serif","fontSize":"16px","lineHeight":"31px","fontWeight":"400","position":"relative","alignSelf":"stretch","flex":"1","display":"flex","alignItems":"center","justifyContent":"flex-start"}}>
                <div className='row justify-content-center align-items-center d-flex signupp'>
                        <div className="password-wrapper">
                             <input
                      type={showPassword ? "text" : "password"}
                      className="font-family-sans-serif emailctrl"
                      id="password"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                                     /> <div class="toggle-button">
                            <button
                                className='btn'
                                type='button'
                                onClick={()=>setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className='me-2 no-border' />
                            </button></div>
                            </div>
                        </div>
     </div>
</div>


                   <div className="mb-3 pt-2">
                  <span className="text-fp text-opacity-50 fs-6 fw-semibold font-family-sans-serif">
                    Forgot Password?&nbsp;&nbsp;&nbsp;&nbsp;<a href="/resetpw" className="textlink-fp">Click here</a>
                  </span>
                </div>

              
           
                <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'
  style={{"background":"#d9d9d9","borderRadius":"10px","borderStyle":"solid","borderColor":"rgba(255, 255, 255, 0.5)","borderWidth":"1px","display":"flex","flexDirection":"row","gap":"10px","alignItems":"center","justifyContent":"center","height":"41px","position":"relative","overflow":"hidden"}}
>
  <div
    style={{"color":"#000000","textAlign":"center","fontFamily":"'Montserrat-Regular', sans-serif","fontSize":"16px","lineHeight":"31px","fontWeight":"400","position":"relative","alignSelf":"stretch","flex":"1","display":"flex","alignItems":"center","justifyContent":"center"}}
  >
              <button type="submit" className="signinbtn" style={{
                fontFamily: 'sans-serif',
                whiteSpace: 'nowrap',
              }}>Sign Up</button>
  </div>
</div>

                                      <div className="form-outline mb-0">
                                    <div className="pt-1 or-text">
                  <span class="line"></span>&nbsp;&nbsp;&nbsp;Or&nbsp;&nbsp;&nbsp;<span class="line"></span>
                  </div>
           
                  </div>

<div className='mb-3 d-grid col-12 mx-auto googlecss justify-content-center align-items-center d-flex'
  style={{"background":"#d9d9d9","borderRadius":"10px","borderStyle":"solid","borderColor":"rgba(255, 255, 255, 0.5)","borderWidth":"1px","display":"flex","flexDirection":"row","gap":"25px","alignItems":"center","justifyContent":"flex-start","flexShrink":"0","height":"41px","position":"relative","overflow":"hidden"}}
>
  <div
    style={{"color":"#000000","textAlign":"left","fontFamily":"'Montserrat-Regular', sans-serif","fontSize":"16px","lineHeight":"31px","fontWeight":"400","position":"relative","alignSelf":"stretch","flex":"1","display":"flex","alignItems":"center","justifyContent":"flex-start"}}
       >
  <GoogleLogin onSuccess={handleLoginSuccess}  buttonText="Sign up with Google" onError={handleLoginError} />
  </div>
</div>
                 <div className="mb-3 ">
                  <span className="text-fpn text-opacity-50 fs-6 fw-semibold font-family-sans-serif">
                  Already have an account?<button type="button" onClick={navigateToLoginPage} className="btn btn-block mt-0 p-0 textlink-login">Log In</button>
                  </span>
                </div>
                
                </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    <Browser size={size} />
    </>

  );
}

export default UserRegister;



