import React, { useState, useEffect,useContext } from "react";
import { useAuth } from '../../AuthContext';
function LandingSectionOne() {
    const { isAuthenticated, user, logout } = useAuth();
    return (
        <div className="container-fluid p-3 mb-2 mt-1 bg-black text-white">
            <div className="container mt-1">
                <div className="bg-black row centered-element">
                    <div className="bg-black col-xxl-6">
                        <video
                            className="videoTag"
                            autoPlay
                            loop
                            muted
                            style={{ width: '100%', height: '77%', objectFit: 'cover' ,opacity: '8',}}
                        >
                            <source src="/videos/landing.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="left-0 col-xxl-12 justify-content-center align-items-center">
                        <div className="col-xxl-12 justify-content-center align-items-center">
                            <div className="position-relative">
                             <div className='p-3'></div>
                            <div className='p-3'></div>
                            <div className='p-3'></div>
                             <div className='p-3'></div>
                             <div className='p-3'></div>
                             <div className='p-3'></div>
                                <p className="text-center m-0 px-0 py-0">
                                    <span className="text-success-subtle fw-bold trading_text">
                                        Sri Lanka’s 1st Social Trading Platform
                                        <br />
                                    </span>
                                    <span className="text-success-subtle fw-normal tradingsmart_text">
                                        Trade smarter, together.
                                        <br />
                                        <br />
                                    </span>
                                    <span className="text-success-subtle fs-connect fw-medium text-connect">
                                        Connect with a community of experienced traders, access live market data, and
                                        <br />
                                        stay informed on the latest stock news to enhance your financial future.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    {isAuthenticated ? (
                        <>
                            <div className="p-4"></div>
                            <div className="left-0 col-xxl-12 display-flex justify-content-center align-items-center">
                                <div className="col-xxl-12 justify-content-center align-items-center">
                                    <div></div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="p-4"></div> <div className="p-1"></div>
                            <div className="left-0 col-xxl-12 display-flex justify-content-center align-items-center">
                                <div className="col-xxl-12 justify-content-center align-items-center">
                                    <div className="position-relative wrapper-3">
                                        <a href="/userregister" className="text-white ms-2">
                                            <span className="text-9">Get Started</span>
                                            <div class="pic-3"></div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LandingSectionOne;
