import React, { useState, useEffect  } from "react";
import './about-us.css'
import SectionOne from './sectionOne';
import SectionTwo from './sectionTwo';
function AboutUs() {
  useEffect(() => {
    document.title = "stockwiseanalytics.com - About Us"; // Dynamically changes the title
  }, []);
    return (
        <>

<div className="container-fluid" style={{padding: '0px'}}>    
         <div className="row py-0">
         <div class="col-md-12" style={{padding: '0px'}}>
         <SectionOne />
            </div>
            </div>
            <div className="row py-0">
            <div class="col-md-12" style={{padding: '0px'}}>
         <SectionTwo/>
            </div>
            </div>
            </div>

</>

    );
}

export default AboutUs;