import axios from 'axios';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";

const fetchCurrentPrice = async (symbol) => {
  const url = `${Base_URL_TV}/tvapi?symbols=[${symbol}]`;
  
  try {
    const response = await axios.get(url);
    const data = response.data;

    // Log the entire API response for debugging purposes
    console.log("API Response:", data);

    // Ensure the response is an array and contains valid data
    if (Array.isArray(data) && data.length > 0 && !data[0].error) {
      console.log("Current Close Price data Received:", data[0].close_price);
      return data[0].close_price;
    } else {
      console.error('Unexpected response format or no data for the symbol:', data);
      throw new Error('No data found for the given symbol');
    }
  } catch (error) {
    // Detailed error handling for different types of issues
    if (error.response) {
      console.error('API response error:', error.response.data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
    throw error;
  }
};

export default fetchCurrentPrice;

