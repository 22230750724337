// export default ReachInfo;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import Collapsible from 'react-collapsible';
const Researchinfo = ({ initialUserData }) => {
    const [rcinfor, setRCinfor] = useState([]);
     useEffect(() => {
        const fetchRCData = async () => {
          try {
            const username = localStorage.getItem('unm');
            console.log('--try--' + username);
            const res = await axios.get(Base_URL + "/research/getresearhinfo", {
              params: { username: username }
            });
            setRCinfor(res.data);
            } catch (error) {
            console.error(error);
            toast.error("Failed to fetch data");
          }
        };
    
        fetchRCData();
      }, []);   

    const handleDownload = async (id) => {
        try {
            const response = await axios.get(`${Base_URL}/research/getresearchfile/${id}`, {
                responseType: 'blob', // Important
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report_${id}.pdf`); // or any other extension
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error downloading the file", error);
            toast.error("Failed to download file");
        }
    };

    return (
        <>
            <p className="text-white fs-6 fw-bold font-family-sans-serif">
                RESEARCH REPORTS & RELATED DOCUMENTS
            </p>
            <div id="component" className="container-fluid py-3">

                <table className="table table-dark font-family-sans-serif">
                    <tbody>
                        {rcinfor.map(rcinf => (
                         <Collapsible trigger={rcinf.sector} className="table table-striped table-dark font-family-sans-serif">
                            <tr key={rcinf.id}>
                                <td class="col-md-12">{rcinf.sector}</td>
                                <td>

 <iframe src={`${Base_URL}/research/getresearchfile/${rcinf.id}`} 
style={{width:'800px', height:'700px'}} frameborder="0"/> </td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleDownload(rcinf.id)}
                                    >
                                        Download PDF
                                    </button>
                                </td>
                            </tr>
                             </Collapsible>
                        ))}
                    </tbody>
                </table>
               
            </div>
        </>
    );
};

export default Researchinfo;
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { Base_URL } from '../../Components/BaseURL.js';
// import Collapsible from 'react-collapsible';

// const Researchinfo = ({ initialUserData }) => {
//     const [rcinfor, setRCinfor] = useState([]);

//     useEffect(() => {
//         const fetchRCData = async () => {
//             try {
//                 const username = localStorage.getItem('unm');
//                 const res = await axios.get(Base_URL + "/research/getresearhinfo", {
//                     params: { username: username }
//                 });
//                 setRCinfor(res.data);
//             } catch (error) {
//                 console.error(error);
//                 toast.error("Failed to fetch data");
//             }
//         };

//         fetchRCData();
//     }, []);

//     const handleDownload = async (id, fileType) => {
//         try {
//             const response = await axios.get(`${Base_URL}/research/getresearchfile/${id}`, {
//                 responseType: 'blob',
//             });
//             const url = window.URL.createObjectURL(new Blob([response.data]));

//             if (fileType === 'pdf') {
//                 // If it's a PDF file, force download with a specified filename
//                 const link = document.createElement('a');
//                 link.href = url;
//                 link.setAttribute('download', `report_${id}.pdf`);
//                 document.body.appendChild(link);
//                 link.click();
//             } else if (fileType === 'ppt') {
//                 // If it's a PowerPoint file, display it in an iframe
//                 const iframe = document.createElement('iframe');
//                 iframe.src = url;
//                 iframe.style.width = '800px';
//                 iframe.style.height = '700px';
//                 iframe.setAttribute('frameborder', '0');
//                 document.getElementById('iframe-container').innerHTML = ''; // Clear previous iframe if any
//                 document.getElementById('iframe-container').appendChild(iframe);
//             }
//         } catch (error) {
//             console.error("Error handling file", error);
//             toast.error("Failed to handle file");
//         }
//     };

//     return (
//         <>
//             <p className="text-white fs-6 fw-bold font-family-sans-serif">
//                 RESEARCH REPORTS & RELATED DOCUMENTS
//             </p>
//             <div id="component" className="container-fluid py-3">
//                 <table className="table table-dark font-family-sans-serif">
//                     <tbody>
//                         {rcinfor.map(rcinf => (
//                             <Collapsible key={rcinf.id} trigger={rcinf.sector} className="table table-striped table-dark font-family-sans-serif">
//                                 <tr>
//                                     <td className="col-md-12">{rcinf.sector}</td>
//                                     <td id={`iframe-container-${rcinf.id}`}>
//                                     <iframe src={`${Base_URL}/research/getresearchfile/${rcinf.id}`} 
//  style={{width:'800px', height:'700px'}} frameborder="0"/> 
//                                     </td>
//                                     {/* <td>
//                                         <button
//                                             className="btn btn-primary"
//                                             onClick={() => handleDownload(rcinf.id, 'pdf')}
//                                         >
//                                             Download PDF
//                                         </button>
//                                         <button
//                                             className="btn btn-primary ms-2"
//                                             onClick={() => handleDownload(rcinf.id, 'ppt')}
//                                         >
//                                             View PowerPoint
//                                         </button>
//                                     </td> */}
//                                 </tr>
//                             </Collapsible>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </>
//     );
// };

// export default Researchinfo;
