import * as React from 'react';
function SectionTwo() {
    return (
        <>
  <div className="container-fluid" style={{padding: '0px'}}>
  <div className='p-3' style={{background: 'rgba(255, 255, 255, 0.03)'}}></div>
  <div className="row col-lg-12 pb-2 pt-2" style={{background: 'rgba(255, 255, 255, 0.03)'}} >

    <div className="col d-inline" style={{width:'1.388rem'}}></div>
    <div className="about-text align-items-center justify-content-center" style={{width:'47.625rem',paddingRight: '2rem'}}>

      At SWA, we are proud to be Sri Lanka’s first social trading platform,
      designed by traders for traders. Our mission is to empower investors of all
      levels to make smarter trading decisions by connecting them with a community
      of experienced traders, providing real-time market data, and delivering
      actionable insights into market trends.
      <br />
      <br />
      We believe that trading shouldn’t be a solo journey. That’s why we’ve built
      a platform where you can follow expert traders, learn from their strategies,
      and even see what they’re buying and selling. Whether you’re new to
      investing or a seasoned trader, SWA gives you the tools and community to
      take your trading to the next level.
      <br />
      <br />
      Built with the trader’s needs in mind, we aim to democratize financial
      knowledge and create an inclusive space where traders can grow together,
      exchange insights, and ultimately succeed. Join us on this journey and
      become part of Sri Lanka’s first and only social trading platform.
    </div>
    <div className="col d-inline" style={{width:'10.125rem',paddingLeft: '2rem',paddingRight: '2rem'}}></div>
    <div className="inline align-items-center justify-content-center" style={{width:'30.938rem',paddingLeft: '2rem'}}>  
    <img className="img-fluid img-fluid-au d-block d-md-none" src="images/about-us/product-img.png" />
    <img className="img-fluid img-main-fluid-au d-none d-md-block" style={{width: '30.938rem', height: '20.625rem'}} src="images/about-us/product-img.png"/>
  </div>
  <div className="col d-inline" style={{width:'1.388rem'}}></div>
  </div>
  <div className='p-3' style={{background: 'rgba(255, 255, 255, 0.03)'}}></div>
  </div>
        </>
    );
}

export default SectionTwo;