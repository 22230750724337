import React, { useState, useEffect ,useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
import ConfirmationDialog_add from "../ConfirmationDialog_add.js";
import ConfirmationDialog_minus from "../ConfirmationDialog_minus.js";
import EditDialog_minus from "./EditDialog_minus.js";
import EditDialog_add from "./EditDialog_add.js";
import FinancialData from "./FinancialData.js";
// import './portfolio.css';
const CurrentPortfolio = ({ initialUserData }) => {
  const [stocks, setStocks] = useState([]);
  const [currentPrices, setCurrentPrices] = useState({}); // To store current prices
  const [updateData, setUpdateData] = useState(null);
  const [editDialogOpen_add, setEditDialogOpen_add] = useState(false);
  const [editDialogOpen_minus, setEditDialogOpen_minus] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const symbolsc =  localStorage.getItem('symbolstring')

  useEffect(() => {
    const fetchPublicUserData = async () => {
      try {
        const username = localStorage.getItem('unm');
        const res = await axios.get(Base_URL + "/portfolio/stocks", {
          params: { username: username }
        });
        setStocks(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };

    fetchPublicUserData();
  }, []);

  // useEffect(() => {
  //   if (stocks.length > 0) {
  //     fetchStockData();
  //   }
  // }, [stocks]);

  useEffect(() => {
    if (symbolsc) {
      fetchStockData();
    }
  }, [symbolsc]);


  const localLogoImagePath = "/images/companylogos";

  const removeData = async (id) => {
    try {
      const res = await axios.delete(Base_URL + "/portfolio/delstock", {
        params: { ordid: id }
      });
      toast.success("Stock Data Successfully deleted.");
      setStocks(prevStocks => prevStocks.filter(stock => stock.ordid !== id));
      navigate(0); 
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmation = (id) => {
    removeData(id);
  };

  const handleStockModal_add = (stock) => {
    setUpdateData(stock);
    setEditDialogOpen_add(true);
  };

  const handleStockModal_minus = (stock) => {
    setUpdateData(stock);
    setEditDialogOpen_minus(true);
  };

  const closeEditDialog_add = () => {
    setEditDialogOpen_add(false);
    setUpdateData(null);
  };

  const closeEditDialog_minus = () => {
    setEditDialogOpen_minus(false);
    setUpdateData(null);
  };

  const fetchStockData = () => {
   // const symbols = stocks.map(stock => stock.ctick);
   const symbols0 = localStorage.getItem('symbolstring');
  let symbolsArray = [];

  if (symbols0) {
    symbolsArray = symbols0.split(','); // Convert string to array
  }
   
   //const url = Base_URL_TV + `/tvapi?symbols=[${symbolsArray.join(',')}]`;
   const url = `${Base_URL_TV}/tvapi?symbols=[${symbolsArray.join(',')}]`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log("Received data:", data);
        // Update state with fetched data
        const prices = data.reduce((acc, stock) => {
          if (!stock.error) {
            acc[stock.symbol] = stock.close_price;
          }
          return acc;
        }, {});
        setCurrentPrices(prices);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
    <div className="container">
      <p className="text-white fs-6 fw-bold font-family-sans-serif px-3">
        CURRENT PORTFOLIO
      </p>
      <div className="row h-25 w-100 px-3">
            <div className="text-white text-opacity-20 fs-6 fw-normal font-family-sans-serif col-10 m-0 px-0 py-2 px-3" style={{ backgroundColor: 'black', width: '100%', height: '270px', overflow: 'auto' }}>

<div style={{"display":"flex","-webkit-flex-direction":"row","-ms-flex-direction":"row","flex-direction":"row","gap":"16px","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center","-webkit-box-pack":"start","-webkit-justify-content":"flex-start","-ms-flex-pack":"start","justify-content":"flex-start","-webkit-flex-shrink":"0","-ms-flex-shrink":"0","flex-shrink":"0","width":"663px","position":"relative"}}>
        <div style={{"display":"flex","-webkit-flex-direction":"row","-ms-flex-direction":"row","flex-direction":"row","gap":"0px","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center","-webkit-box-pack":"start","-webkit-justify-content":"flex-start","-ms-flex-pack":"start","justify-content":"flex-start","-webkit-flex":"1","-ms-flex":"1","flex":"1","position":"relative"}}>
          <div style={{"color":"#ffffff","-webkit-text-align":"left","text-align":"left","font-family":"'Montserrat-SemiBold', sans-serif","font-size":"14px","line-height":"140%","letter-spacing":"-0.01em","font-weight":"600","position":"relative"}}>
            Stock
          </div>
        </div>
        <div style={{"display":"flex","-webkit-flex-direction":"row","-ms-flex-direction":"row","flex-direction":"row","gap":"0px","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center","-webkit-box-pack":"start","-webkit-justify-content":"flex-start","-ms-flex-pack":"start","justify-content":"flex-start","-webkit-flex-shrink":"0","-ms-flex-shrink":"0","flex-shrink":"0","width":"69px","position":"relative"}}>
          <div style={{"color":"#ffffff","-webkit-text-align":"left","text-align":"left","font-family":"'Montserrat-SemiBold', sans-serif","font-size":"14px","line-height":"140%","letter-spacing":"-0.01em","font-weight":"600","position":"relative"}}>
            Quantity
          </div>
        </div>
        <div style={{"display":"flex","-webkit-flex-direction":"row","-ms-flex-direction":"row","flex-direction":"row","gap":"0px","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center","-webkit-box-pack":"start","-webkit-justify-content":"flex-start","-ms-flex-pack":"start","justify-content":"flex-start","-webkit-flex-shrink":"0","-ms-flex-shrink":"0","flex-shrink":"0","width":"100px","position":"relative"}}>
          <div style={{"color":"#ffffff","-webkit-text-align":"left","text-align":"left","font-family":"'Montserrat-SemiBold', sans-serif","font-size":"14px","line-height":"140%","letter-spacing":"-0.01em","font-weight":"600","position":"relative","width":"100px"}}>
            Avg. Price Purchased
          </div>
        </div>
        <div style={{"display":"flex","-webkit-flex-direction":"row","-ms-flex-direction":"row","flex-direction":"row","gap":"0px","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center","-webkit-box-pack":"start","-webkit-justify-content":"flex-start","-ms-flex-pack":"start","justify-content":"flex-start","-webkit-flex-shrink":"0","-ms-flex-shrink":"0","flex-shrink":"0","width":"100px","position":"relative"}}>
          <div style={{"color":"#ffffff","-webkit-text-align":"left","text-align":"left","font-family":"'Montserrat-SemiBold', sans-serif","font-size":"14px","line-height":"140%","letter-spacing":"-0.01em","font-weight":"600","position":"relative"}}>
            Current Price
          </div>
        </div>
        <div style={{"display":"flex","-webkit-flex-direction":"row","-ms-flex-direction":"row","flex-direction":"row","gap":"0px","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center","-webkit-box-pack":"start","-webkit-justify-content":"flex-start","-ms-flex-pack":"start","justify-content":"flex-start","-webkit-flex-shrink":"0","-ms-flex-shrink":"0","flex-shrink":"0","width":"100px","position":"relative"}}>
          <div style={{"color":"#ffffff","-webkit-text-align":"left","text-align":"left","font-family":"'Montserrat-SemiBold', sans-serif","font-size":"14px","line-height":"140%","letter-spacing":"-0.01em","font-weight":"600","position":"relative"}}>
            Action
          </div>
        </div>
       </div>

       {stocks.map(stock => (
       <><div  key={stock.ordid} style={{ "background": "var(--light-theme-gray-light-grey, #dfe5ef)", "-webkit-flex-shrink": "0", "-ms-flex-shrink": "0", "flex-shrink": "0", "width": "663px", "height": "1px", "position": "relative" }} /><div style={{ "display": "flex", "-webkit-flex-direction": "row", "-ms-flex-direction": "row", "flex-direction": "row", "gap": "16px", "-webkit-align-items": "center", "-webkit-box-align": "center", "-ms-flex-align": "center", "align-items": "center", "-webkit-box-pack": "start", "-webkit-justify-content": "flex-start", "-ms-flex-pack": "start", "justify-content": "flex-start", "-webkit-flex-shrink": "0", "-ms-flex-shrink": "0", "flex-shrink": "0", "width": "663px", "position": "relative" }}>
           <div style={{ "display": "flex", "-webkit-flex-direction": "row", "-ms-flex-direction": "row", "flex-direction": "row", "gap": "12px", "-webkit-align-items": "center", "-webkit-box-align": "center", "-ms-flex-align": "center", "align-items": "center", "-webkit-box-pack": "start", "-webkit-justify-content": "flex-start", "-ms-flex-pack": "start", "justify-content": "flex-start", "-webkit-flex": "1", "-ms-flex": "1", "flex": "1", "position": "relative" }}>
             <img className="rectangle-154" style={{ "border-radius": "8px", "-webkit-flex-shrink": "0", "-ms-flex-shrink": "0", "flex-shrink": "0", "width": "48px", "height": "48px", "position": "relative", "object-fit": "cover" }}  src={stock.ctick ? `${localLogoImagePath}/${stock.ctick}.png` : '/images/companylogos/default-logo.png'} />
             <div style={{ "display": "flex", "-webkit-flex-direction": "column", "-ms-flex-direction": "column", "flex-direction": "column", "gap": "4px", "-webkit-align-items": "flex-start", "-webkit-box-align": "flex-start", "-ms-flex-align": "flex-start", "align-items": "flex-start", "-webkit-box-pack": "start", "-webkit-justify-content": "flex-start", "-ms-flex-pack": "start", "justify-content": "flex-start", "-webkit-flex": "1", "-ms-flex": "1", "flex": "1", "position": "relative" }}>
               <div style={{ "color": "#ffffff", "-webkit-text-align": "left", "text-align": "left", "font-family": "'Montserrat-SemiBold', sans-serif", "font-size": "14px", "line-height": "140%", "letter-spacing": "-0.01em", "font-weight": "600", "position": "relative", "-ms-flex-item-align": "stretch", "align-self": "stretch" }}>
               {stock.cname}
               </div>
               <div style={{ "color": "#ffffff", "-webkit-text-align": "left", "text-align": "left", "font-family": "sans-serif,'Montserrat-Regular'", "font-size": "12px", "line-height": "140%", "font-weight": "400", "position": "relative", "-ms-flex-item-align": "stretch", "align-self": "stretch" }}>
               {stock.ctick}
               </div>
             </div>
           </div>
           <div style={{ "display": "flex", "-webkit-flex-direction": "row", "-ms-flex-direction": "row", "flex-direction": "row", "gap": "0px", "-webkit-align-items": "center", "-webkit-box-align": "center", "-ms-flex-align": "center", "align-items": "center", "-webkit-box-pack": "start", "-webkit-justify-content": "flex-start", "-ms-flex-pack": "start", "justify-content": "flex-start", "-webkit-flex-shrink": "0", "-ms-flex-shrink": "0", "flex-shrink": "0", "width": "69px", "position": "relative" }}>
             <div style={{ "color": "#ffffff", "-webkit-text-align": "right", "text-align": "right", "font-family": "sans-serif,'Montserrat-Regular'", "font-size": "14px", "line-height": "140%", "letter-spacing": "-0.02em", "font-weight": "400", "position": "relative" }}>
             {stock.csemco_quantity}
             </div>
           </div>
           <div style={{ "display": "flex", "-webkit-flex-direction": "row", "-ms-flex-direction": "row", "flex-direction": "row", "gap": "0px", "-webkit-align-items": "center", "-webkit-box-align": "center", "-ms-flex-align": "center", "align-items": "center", "-webkit-box-pack": "start", "-webkit-justify-content": "flex-start", "-ms-flex-pack": "start", "justify-content": "flex-start", "-webkit-flex-shrink": "0", "-ms-flex-shrink": "0", "flex-shrink": "0", "width": "100px", "position": "relative" }}>
             <div style={{ "color": "#ffffff", "-webkit-text-align": "right", "text-align": "right", "font-family": "sans-serif,'Montserrat-Regular'", "font-size": "14px", "line-height": "140%", "letter-spacing": "-0.02em", "font-weight": "400", "position": "relative" }}>
             {stock.avgprice}
             </div>
           </div>
           <div style={{ "display": "flex", "-webkit-flex-direction": "row", "-ms-flex-direction": "row", "flex-direction": "row", "gap": "0px", "-webkit-align-items": "center", "-webkit-box-align": "center", "-ms-flex-align": "center", "align-items": "center", "-webkit-box-pack": "start", "-webkit-justify-content": "flex-start", "-ms-flex-pack": "start", "justify-content": "flex-start", "-webkit-flex-shrink": "0", "-ms-flex-shrink": "0", "flex-shrink": "0", "width": "100px", "position": "relative" }}>
             <div style={{ "color": "#ffffff", "-webkit-text-align": "right", "text-align": "right", "font-family": "sans-serif,'Montserrat-Regular'", "font-size": "14px", "line-height": "140%", "letter-spacing": "-0.02em", "font-weight": "400", "position": "relative" }}>
             {currentPrices[stock.ctick] !== undefined ? currentPrices[stock.ctick].toFixed(2) : 'N/A'}
             </div>
           </div>
           <div style={{ "display": "flex", "-webkit-flex-direction": "row", "-ms-flex-direction": "row", "flex-direction": "row", "gap": "0px", "-webkit-align-items": "center", "-webkit-box-align": "center", "-ms-flex-align": "center", "align-items": "center", "-webkit-box-pack": "start", "-webkit-justify-content": "flex-start", "-ms-flex-pack": "start", "justify-content": "flex-start", "-webkit-flex-shrink": "0", "-ms-flex-shrink": "0", "flex-shrink": "0", "width": "100px", "position": "relative" }}>
                         <ConfirmationDialog_add
                      title="Confirmation"
                      description="Are you sure you want to proceed?"
                      response={() => handleConfirmation(stock.ordid)}>
                      {(showDialog) => (
                        <i className="bi bi-trash" onClick={showDialog} style={{ color: 'rgb(255, 0, 0)', fontSize: '1rem' }}></i>
                      )}
                    </ConfirmationDialog_add>
                    &nbsp;
                    <TwitterShareButton
                      title={`Hey, I just purchased ${stock.csemco_quantity} stocks from ${stock.cname}. My current avg. price is ${stock.avgprice}`}
                      url={'https://www.stockwiseanalytics.com'}
                      hashtags={['', '']}
                    >
                      <i className="bi bi-twitter-x" style={{ color: '#fff', fontSize: '1rem' }}></i>
                    </TwitterShareButton>
                    &nbsp;
                    <a href="#" onClick={() => handleStockModal_add(stock)}>
                      <i className="bi bi-file-earmark-plus" style={{ color: 'gold', fontSize: '1rem' }}></i>
                    </a>
                    &nbsp;
                    <a href="#" onClick={() => handleStockModal_minus(stock)}>
                      <i className="bi bi-file-earmark-minus" style={{ color: 'cyan', fontSize: '1rem' }}></i>
                    </a>
           </div>
         </div></>
 ))}



        </div>
      </div>
      </div>
      <EditDialog_add
        open={editDialogOpen_add}
        onClose={closeEditDialog_add}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          setStocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
      />
      <EditDialog_minus
        open={editDialogOpen_minus}
        onClose={closeEditDialog_minus}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          setStocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
      />
    </>
  );
};

export default CurrentPortfolio;

