import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../../../../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faUser } from '@fortawesome/free-solid-svg-icons';
import './Header.css'; // Import specific CSS for Header
import ImageDisplay from '../../../../page/ImageDisplay';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useWindowSize } from "@uidotdev/usehooks";
function Browser({ size }) {
    return (
      <div
        data-testid="browser"
        className="browser"
        style={{ width: size.width / 4, height: size.height / 4 }}
      />
    );
  }
function Header() {
    const size = useWindowSize();
    const [open, setOpen] = React.useState(false);
    const [openprofile, setOpenprofile] = React.useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isSubNavOpen, setIsSubNavOpen] = useState(false);
    const [isNavOpenlinu, setIsNavOpenlinu] = useState(false);
    const [isSubNavOpenlinu, setIsSubNavOpenlinu] = useState(false);
    const { isAuthenticated, user, logout } = useAuth();//---
    const [imageSrc, setImageSrc] = useState('');
    const userid = localStorage.getItem('userid');
    const username = localStorage.getItem('unm');
    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: 'rgba(256,256,256, 0.3)',
                backgroundColor: 'rgba(256,256,256, 0.3)',
                height: 1
            }}
        />
    );
    const HandleIsSubNavOpenlinu = () => {
        setOpen(false);
        setIsSubNavOpenlinu(true);
        setOpenprofile(!openprofile);
      };

    const handleClick = () => {
      setOpen(!open);
      setIsSubNavOpenlinu(false);
      setOpenprofile(false);
    };
  ;
    


    return (
            <header className="bg-black text-white p-1 header" style={{paddingLeft: '0px',paddingRight: '0px'}}>
               <div className="-fluid justify-content-between align-items-center"
               style={{marginLeft: '0px',margin: '0px', padding: '0px',backgroundColor:'rgba(0, 0, 0, 0.5)'}}>
                    <a href="/">
                    <img className="img-fluid d-md-none" style={{ maxWidth: '45px',height:'auto' }} src="/images/SVAlogo.png" alt="Logo" />
                    <img className="img-main-fluid d-none d-md-block header-logo"  src="/images/SVAlogoWithName.png" alt="Logo" />
                </a>
                  <div className="align-items-center gap-4">                     
                            {/* <div className="relative" onClick={handleClick}></div> */}
                             <a href="/portfolio" className="header-button nav-link text-white text-nowrap header-menutext">Portfolio</a>
                             <div className="d-flex align-items-start text-white text-base font-normal"  style={{cursor:'pointer'}}>
                            <div className="text-start  cursor-pointer">
                             <a href="/aboutUs" className="header-button nav-link text-white text-nowrap header-menutext">About Us</a>
                            </div>
                            </div>
                            {/* <div className="d-flex align-items-start text-white text-base font-normal header-menutext mr-md-5 mr-lg-5 mr-xl-5  d-flex justify-content-between w-100"  style={{cursor:'pointer'}}>
                            <div className="text-start cursor-pointer">
                            
                            </div>
                            </div> */}
   
                        {isAuthenticated ? (
                <>
                
               <div className="relative" onClick={() => HandleIsSubNavOpenlinu()}   >
                                <div className="d-flex align-items-start text-white text-base font-normal cursor-pointer">
                                    <div className="text-start" style={{ width: '86px', height: '23px' }}>
                                        {/* services */}
                                        <div className="Avatar" style={{width: 32, height: 32, position: 'relative',cursor:'pointer'}}>
    <ImageDisplay imageId={userid} imageFrom="Header"/>
    <img style={{width: 9, height: 10, left: 23, top: 0, position: 'relative'}} src="images/redbell.png" />
  </div>

                                    </div>
                                    <div className="ms-0">
                                    </div>
                                </div>
                                {isSubNavOpenlinu && (
                                     <Collapse in={openprofile} timeout="auto" unmountOnExit>
                                    <div className="dropdown-menu dropdown-menur show bg-dark text-white mt-0 p-2 rounded shadow dropdown-menu-sm-end" style={{ minWidth: 'fit-content', width: '12rem' }} aria-labelledby="dropdownMenuButton">
                                       <center><small>{username}</small></center>
                                       <ColoredLine/>
                                        <a href="/profilesettings" className="dropdown-item text-white">Profile Settings</a>
                                        <a href="/portfolio" className="dropdown-item text-white">Portfolio Analytics</a>
                                        <a href="#" onClick={logout} className="dropdown-item text-white">Sign Out</a>
                                    </div>
                                    </Collapse>
                                )}
                            </div>
        
                </>
              ) : (
                <>
                  <div className="d-flex align-items-start text-white text-base font-normal"  style={{cursor:'pointer'}}>
                  <div className="text-start  cursor-pointer">
                <a href='/userregister' className="header-button nav-link text-white text-nowrap header-menutext"  style={{textDecoration: 'none'}}>
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18.8049V16.7327H16V2.22735H9V0.155151H16C16.55 0.155151 17.021 0.358227 17.413 0.764378C17.805 1.17053 18.0007 1.65819 18 2.22735V16.7327C18 17.3026 17.8043 17.7906 17.413 18.1967C17.0217 18.6029 16.5507 18.8056 16 18.8049H9ZM7 14.6605L5.625 13.1582L8.175 10.5161H0V8.44395H8.175L5.625 5.80189L7 4.29955L12 9.48005L7 14.6605Z" fill="#F8F8F8" />
                    </svg>
                         &nbsp;&nbsp;Login/ SignUp
                <button onClick={() => setIsNavOpenlinu(!isNavOpenlinu)} className="btn btn-link text-white d-lg-none p-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isNavOpenlinu ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"} />
                    </svg>
                </button>
                </a>
                </div>
                </div>
                {/* <div className="">
                <a href='/userregister' className="text-white ms-2"  style={{textDecoration: 'none'}}>
                <div className="pe-4 d-flex align-items-center">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18.8049V16.7327H16V2.22735H9V0.155151H16C16.55 0.155151 17.021 0.358227 17.413 0.764378C17.805 1.17053 18.0007 1.65819 18 2.22735V16.7327C18 17.3026 17.8043 17.7906 17.413 18.1967C17.0217 18.6029 16.5507 18.8056 16 18.8049H9ZM7 14.6605L5.625 13.1582L8.175 10.5161H0V8.44395H8.175L5.625 5.80189L7 4.29955L12 9.48005L7 14.6605Z" fill="#F8F8F8" />
                    </svg>
                    <div className="d-none d-sm-block text-center text-white text-base font-normal ms-0 ps-3 header-menutext text-nowrap">Login/ SignUp</div>
                </div>
                <button onClick={() => setIsNavOpenlinu(!isNavOpenlinu)} className="btn btn-link text-white d-lg-none p-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isNavOpenlinu ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"} />
                    </svg>
                </button>
                </a>
            </div> */}
            </>
              )}

                   </div>
            </div>
            <Browser size={size} />
        </header>
    );
}

export default Header;
