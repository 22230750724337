// LandingSectionTwo.js

import React, { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
function LandingSectionThree( className,
    style,
    ...props) {
    const navigate = useNavigate();
    function navigateTo(path) {
        navigate(path);
      }
    function handleExplore(evt) {
        navigateTo("/explore");
      }
      function handleExplorefirstedge(evt) {
        navigateTo("/firstedge");
      } 

    return (
        <>   
        <div className='p-4'></div>   
     <div className="container-fluid featurescss" style={{ height: "auto",width:'78%', position: "relative", ...style }}>
     <div className="row">
      <div className="col-md-12 d-flex flex-column justify-content-center" style={{paddingLeft: '0px', paddingRight: '0px'}}>
     <div class="container mt-0">
  <div class="row">
   <div class="text-white text-center p-3"><img src="/images/eye.png" style={{width: 100, height: 68}}></img></div>
  </div>
  <div class="container mt-0">
  <div class="text-white text-center p-2">
  <div style={{textAlign: 'center', color: '#D3D9D5',fontSize: '20px', fontFamily: 'sans-serif',fontWeight: '700',wordWwrap: 'break-word'}}>Portfolio Analytics</div>
            
            </div>

  <div class="row">
  <div class="text-white text-center p-3">
    
            <span style={{color: '#D3D9D5', fontSize: 16, fontFamily: 'sans-serif', fontWeight: '400', wordWrap: 'break-word'}}>
            Portfolio Analytics Tool helps you stay on top of the market by allowing you to </span>
            <span style={{color: '#D3D9D5', fontSize: 16, fontFamily: 'sans-serif', fontWeight: '700', wordWrap: 'break-word'}}>create and manage your own portfolio, track daily market movers, and understand market sentiment at a glance.</span>
            </div>
            </div>

            <div class="row">
  <div class="text-white text-center p-3">
            <span style={{color: '#D3D9D5', fontSize: 20, fontFamily: 'sans-serif', fontWeight: '700', wordWrap: 'break-word'}}>
            Features: </span>
            
            </div>
            </div>
            </div>


<div class="row">
    <div class="d-flex justify-content-center">
        <div class="d-flex flex-wrap gap-3 align-items-center">
            <div class="flex-grow-1 card bg-dark text-white">
            <div class="card"  style={{background:'#191919'}}>
        <div class="card-body" style={{width: 319, height: 239, borderRadius: 20}} >
          <div class="box-satu d-flex flex-column justify-content-center align-items-center">
            <div class="avatar position-relative d-flex flex-column justify-content-center align-items-center">
              {/* <div class=""> */}
                <div class="mt-1 d-flex flex-column justify-content-center align-items-center p-2 mb-3"><img src="./images/img-10.png" style={{width: '39px', height: '39px'}}></img></div>

                
                <div class="row">
                  <div class="col-12 mb-3">
                  <span className="feature_title d-flex flex-column justify-content-center align-items-center" >
                    Create and manage your own portfolio.
                  </span>
                 </div>           
                </div>
                <div class="row">
                  <div class="col-12">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                 Build and monitor your
                </span>
                 </div>          
                </div>
                <div class="row">
                  <div class="col-12">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                 personalized investment portfolio 
                </span>
                 </div>          
                </div>
                <div class="row">
                  <div class="col-12">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                 with ease.
                </span>
                 </div>          
                </div>
                 </div>
            </div>
        </div>
       </div>
                </div>
            <div class="flex-grow-1 card text-white" >
            <div class="card" style={{background:'#191919'}}>
        <div class="card-body">
          <div class="box-satu d-flex flex-column justify-content-center align-items-center">
            <div class="avatar position-relative d-flex flex-column justify-content-center align-items-center">
              {/* <div class=""> */}
                <div class="mt-1 d-flex flex-column justify-content-center align-items-center p-2 mb-3"><img src="./images/img-6.png" style={{width: '39px', height: '39px'}}></img></div>

                
                <div class="row">
                <div class="col-12 mb-3">
                  <span className="feature_title d-flex flex-column justify-content-center align-items-center" >
                  What is moving today?
                  </span>
                 </div>           
                </div>
                <div class="row">
                  <div class="col">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                 Get real-time insights on the top-
                </span>
                 </div>          
                </div>
                <div class="row">
                  <div class="col">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                  performing stocks and the biggest
                </span>
                 </div>          
                </div>
                <div class="row">
                  <div class="col">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                  market movers.
                </span>
                 </div>          
                </div>
                 </div>
            </div>
        </div>
       </div>
                </div>
   <div class="flex-grow-1 card text-white">
    <div class="card"  style={{background:'#191919'}}>
        <div class="card-body">
          <div class="box-satu d-flex flex-column justify-content-center align-items-center">
            <div class="avatar position-relative d-flex flex-column justify-content-center align-items-center">
              {/* <div class=""> */}
                <div class="mt-1 d-flex flex-column justify-content-center align-items-center p-2 mb-3"><img src="./images/img-8.png" style={{width: '39px', height: '39px'}}></img></div>

                
                <div class="row">
                  <div class="col-12 mb-3">
                  <span className="feature_title d-flex flex-column justify-content-center align-items-center" >
                  Market Sentiment
                  </span>
                 </div>           
                </div>
                <div class="row">
                  <div class="col">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                  Gauge how the market feels about
                </span>
                 </div>          
                </div>
                <div class="row">
                  <div class="col">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                  different stocks and sectors with
                </span>
                 </div>          
                </div>
                <div class="row">
                  <div class="col">
                  <span className="feature_text d-flex flex-column justify-content-center align-items-center">
                  sentiment analysis.
                </span>
                 </div>          
                </div>
                 </div>
            </div>
        </div>
       </div>
                </div>
        </div>
    </div>
</div>




  <div class="row">
  <div class="container d-flex justify-content-center" style={{paddingLeft:'0px',paddingRight: '0px', marginLeft: '15px', marginRight: '15px'}}>    
    <div class="col-lg-4 d-flex justify-content-center align-items-center" onClick={handleExplore}  style={{cursor: 'pointer',width: '319', height: '239', borderRadius: '20',paddingRight: '0px',paddingLeft: '0px'}}>

    </div>
    <div class="card-deck col-lg-4 d-flex justify-content-center align-items-center" onClick={handleExplore}  style={{cursor: 'pointer',width: '319', height: '239',  borderRadius: '20',paddingRight: '0px',paddingLeft: '0px'}}>

  </div>
  </div>
  <div class="row">
  <div class="text-white text-center p-3">
  <a className="content" href={'/explore'} > <span style={{width: 346, height: 54, textAlign: 'center', color: '#D3D9D5', fontSize: 16, fontFamily: 'sans-serif', fontWeight: '300', textDecoration: 'underline', wordWrap: 'break-word'}}>
            Explore Features </span></a>
            
            </div>
            </div>
</div>
</div>



</div>
   
    
      </div>
    </div>
        </>
    );
}

export default LandingSectionThree;



