// LandingSectionTwo.js

import React, { useState, useEffect,useContext } from "react";
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './CarouselImage';


function LandingSectionTwo() {

    return (
        <>
 <div className='p-4'></div>
 <div className='p-3'></div>
 <div class="container-fluid">
<div class="row">
<div class="p-1"></div>
 <p class="col-md-12 d-flex flex-column justify-content-center text-center text-success-subtle fs-explore fw-normal font-family-sans-serif  m-0 px-3 py-2 carousel_toptext">
    Explore the first-ever social trading platform in Sri Lanka. Be part of a community where you can follow<br />expert traders, share insights, access real-time market data, and stay updated with the latest stock news.
    </p>
                    </div>
                    <div className='p-4'></div><div className='p-3'></div><div className='p-2'></div><div className='p-2'></div>
                      <div style={{ display: 'block', height: 'auto', width: '100%', backgroundColor: 'black' }}>
                <div className='container-fluid'>
                <div className="row">
                <div className="col-md-12">
                    <Carousel controls={true}>
                        <Carousel.Item>
                            <ExampleCarouselImage className="Carousel_h"
                                CarouselHeader="Connect with Top Traders" 
                                CarouselContent="See what leading traders are buying and selling in real-time."
                                src='/images/carousel/carosel-1.png' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <ExampleCarouselImage className="Carousel_h"
                                CarouselHeader="Live Market Data & Insights"
                                CarouselContent="Access live stock data, market news, and analysis to"
                                CarouselContent1="stay informed on the latest trends."
                                src='/images/carousel/carosel-2.png' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <ExampleCarouselImage className="Carousel_h"
                                CarouselHeader="Powerful Investment Tools"
                                CarouselContent="Engage with tools that provide market insights, help "
                                CarouselContent1=" refine strategies, and enhance your trading experience."
                                src='/images/carousel/carosel-3.png' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <ExampleCarouselImage className="Carousel_h"
                                CarouselHeader="Personalized Portfolio Insights"
                                CarouselContent="Get tailored recommendations and insights based on"
                                CarouselContent1="your portfolio and trading activities."
                                src='/images/carousel/carosel-4.png' />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            </div>
            </div>
</div>

      </>
    );
}

export default LandingSectionTwo;

