import React, {  useEffect } from "react";

function SectionOne() {
    useEffect(() => {
        // Apply styles on mount
        document.body.style.overflowX = 'hidden';      
        // Cleanup on unmount
        return () => {
          document.body.style.overflowX = '';
        };
      }, []);
    return (
        <>

<div class="container-fluid" style={{padding: '0px'}}>
<div class="row">
<div class="col-xs-12">
        <div style={{ height: '364px', position: 'relative', width: '100%', marginTop: "70px" ,backgroundColor:"black" }}>
            <video className='videoTagAU' autoPlay loop muted webkit-playsinline style={{ width: '100%', height: '100%', objectFit: 'cover',background: 'rgba(3, 4, 7, 0.8)'}}>
                <source src='/videos/aboutUs.mp4' type='video/mp4' />
            </video>

<div className="overlay-text">
    <h1>About Us</h1>
  </div>





 </div>
 </div>
 </div>
 </div>

        </>
    );
}

export default SectionOne;