// export default FinancialInfo;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import fetchCPrice from './fetchCurrentPrice';

const FinancialInfo = () => {
    const [fininfor, setFininfor] = useState([]);
    const [matchingData, setMatchingData] = useState({});
    const [stocks, setStocks] = useState([]);
    const [symbols, setSymbols] = useState([]);

    const username = localStorage.getItem('unm');
    const localLogoImagePath = "/images/companylogos";

    // Fetch stocks data and update localStorage
    useEffect(() => {
        const fetchPublicUserData = async () => {
            try {
                const res = await axios.get(Base_URL + "/portfolio/stocks", {
                    params: { username }
                });

                setStocks(res.data);
                const fetchedSymbols = res.data.map(stock => stock.ctick);
                setSymbols(fetchedSymbols); // Update state
                localStorage.setItem('symbolstring', JSON.stringify(fetchedSymbols));
            } catch (error) {
                console.error(error);
                toast.error("Failed to fetch data");
            }
        };

        fetchPublicUserData();
    }, [username]);

    // Convert symbols array to string for API request
    const getResultString = (symbols) => {
        if (symbols.length === 0) return '';
        return symbols.map(str => `'${str}'`).join(',');
    };

    const resultString = getResultString(symbols);

    // Fetch financial info based on resultString
    useEffect(() => {
        const fetchFiniData = async () => {
            try {
                const res = await axios.get(Base_URL + "/fininfo/getfininfo", {
                    params: { username, symbols: resultString }
                });

                setFininfor(res.data);

                const pricesPromises = res.data.map(async (fininf) => {
                    try {
                        const price = await fetchCPrice(fininf.stock_name);
                        return { [fininf.stock_name]: price };
                    } catch (error) {
                      return { [fininf.stock_name]: null };
                    }
                });

                const pricesData = await Promise.all(pricesPromises);
                const pricesObject = pricesData.reduce((acc, curr) => ({ ...acc, ...curr }), {});
                setMatchingData(pricesObject);

            } catch (error) {
                console.error(error);
                toast.error("Failed to fetch data");
            }
        };

        if (symbols.length > 0) {
            fetchFiniData();
        }
    }, [username, resultString]);

    return (
        <div className="container-fluid">
   <p className="text-white fs-6 fw-bold font-family-sans-serif px-4">
                    FINANCIAL INFORMATION
                </p>
   
            <div className="row h-25 w-100  px-4">
                <div
                    className="text-white text-opacity-50 fs-6 fw-normal font-family-sans-serif col-10 m-0 py-2"
                    style={{ backgroundColor: 'black', width: '100%', height: '300px',  overflowX: 'hidden'  }}
                >
                    <table className="table table-striped table-dark font-family-sans-serif mytablefi" style={{ overflowX: 'hidden' }}>
                        <thead>
                            <tr className="border-bottom border-primary-subtle border-opacity-25">
                                <th className="fininfo_header"  width={'3%'}>Stock</th>
                                <th className="fininfo_header"  width={'7%'}></th>
                                <th className="fininfo_header"  width={'10%'}>Market Capitalization (LKR)</th>
                                <th className="fininfo_header"  width={'10%'}>Earnings Per Share (LKR)</th>
                                <th className="fininfo_header"  width={'10%'}>Net Asset Value (LKR)</th>
                                <th className="fininfo_header"  width={'10%'}>Price-to-Earnings Ratio</th>
                                <th className="fininfo_header"  width={'10%'}>Price-to-Book Ratio</th>
                                <th className="fininfo_header"  width={'10%'}>Dividend Yield</th>
                                <th className="fininfo_header"  width={'15%'}>Top Shareholders</th>
                                <th className="fininfo_header"  width={'10%'}>Share Ownership</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fininfor.length === 0 ? (
                                <tr>
                                    <td colSpan="9">No data available</td>
                                </tr>
                            ) : (
                                fininfor.map(fininf => (
                                    <tr key={fininf.id} className="border-bottom border-secondary border-opacity-25">
                                         <td>
                    <img
                    style={{
                        borderRadius: "8px",
                        flexShrink: "0",
                        width: "48px",
                        height: "48px",
                        position: "relative",
                        objectFit: "cover",
                      }}
                      src={fininf.stock_name  ? `${localLogoImagePath}/${fininf.stock_name }.png` : '/images/companylogos/default-logo.png'}
                      alt={''}
                    />
                  </td>
                   <td align="td_start">
                          <span className="fininfo_dtl">{fininf.stock_name}</span>
                                        </td>
                                        <td className="fininfo_dtl">{fininf.market_capitalization}</td>
                                        <td className="fininfo_dtl" align="center">{fininf.earning_per_share}</td>
                                        <td className="fininfo_dtl" align="center">{fininf.net_asset_values}</td>
                                        <td className="fininfo_dtl" align="center">{matchingData[fininf.stock_name] ? (fininf.earning_per_share / matchingData[fininf.stock_name]).toFixed(2) : 'N/A'}</td>
                                        <td className="fininfo_dtl" align="center">{matchingData[fininf.stock_name] ? (fininf.net_asset_values / matchingData[fininf.stock_name]).toFixed(2) : 'N/A'}</td>
                                        <td className="fininfo_dtl" align="center">{matchingData[fininf.stock_name] ? (fininf.dividend_per_share / matchingData[fininf.stock_name]).toFixed(2)+'%' : 'N/A'}</td>
                                        <td className="fininfo_dtl td_start text-nowrap">{fininf.top_shareholders}</td>
                                        <td className="fininfo_dtl" align="center">{fininf.share_ownership}%</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FinancialInfo;
