import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_URL } from '../Components/BaseURL';

const ImageDisplay = ({ imageId }) => {
  const [imageSrc, setImageSrc] = useState('/images/companylogos/default-logo.png'); // Default image initially

  useEffect(() => {
    const fetchImage = async () => {
      if (!imageId) {
        console.warn('No imageId provided. Using default image.');
       // return setImageSrc('/images/companylogos/default-logo.png');
      }

      try {
        const response = await axios.get(`${Base_URL}/upimage/image/${imageId}`, {
          responseType: 'arraybuffer',
        });
        if(response.status === 500){
        //  setImageSrc('/images/companylogos/default-logo.png');
          return; 
        } 
/////////////
        const contentType = response.headers['content-type'] || 'image/jpeg'; // Fallback to image/jpeg
        const base64Image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );
        const imageSrc = `data:${contentType.toLowerCase()};base64,${base64Image}`;
        // console.log('Image source fetched:', imageSrc);
        
        if ((imageSrc === 'data:null;base64,') || (imageSrc === 'data:image/png;base64,')) { 
          console.log('Fetched image is empty, using default image.');
         // setImageSrc('/images/companylogos/default-logo.png');
        } else {
          setImageSrc(imageSrc);
        }
      } catch (error) {
      //  setImageSrc('/images/companylogos/default-logo.png');
        return;
      }
    };

    fetchImage();
  }, [imageId]);

  return (
    <div>
      <img
        className="Ellipse16"
        style={{
          width: 32,
          height: 32,
          left: 0,
          top: 0,
          position: 'absolute',
          borderRadius: 9999,
        }}
        src={imageSrc}
        alt="Uploaded"
      />
    </div>
  );
};

export default ImageDisplay;
