// components/Footer.js
import React, { useState, useEffect ,useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css'
import { useWindowSize } from "@uidotdev/usehooks";
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
const Footer = () => {
  const size = useWindowSize();
    return (       
      <>
       <div style={{height: '208px', position: 'relative'}}>
        <div style={{background: '#000000', width: '100%', height: '100%', position: 'absolute', right: '0%', left: '0%', bottom: '0%', top: '0%'}} />
        <div style={{color: '#ffffff', textAlign: 'justified', fontFamily: '"Montserrat-Light", sans-serif', fontSize: '16px', fontWeight: 300, position: 'absolute', right: '4.24%', left: '35.49%', width: '60.28%', bottom: '28.85%', top: '28.85%', height: '42.31%', display: 'flex', alignItems: 'center'}}>
          Join the revolution in trading and be part of Sri Lanka’s first-ever social
          trading platform. Get real-time insights<br /> into the market, connect with top
          traders, stay updated with stock news, and grow your portfolio—together.
        </div>
        <div style={{color: '#ffffff', textAlign: 'left', fontFamily: '"Montserrat-Regular", sans-serif', fontSize: '18px', fontWeight: 400, position: 'absolute', right: '69.79%', left: '6.53%', width: '23.68%', bottom: '15.38%', top: '66.35%', height: '18.27%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
          Sri Lanka’s 1st Social Trading Platform
        </div>
        <div style={{width: '23.33%', height: '55.29%', position: 'absolute', right: '69.93%', left: '6.74%', bottom: '28.85%', top: '15.87%'}}>
        <a href="/"><img class="img-fluid d-md-none" src="/images/SVAlogo.png" alt="Logo" style={{maxWidth: '45px', height: 'auto'}}/>
       <img class="img-footer-fluid d-none d-md-block header-logo" src="/images/SVAlogoWithName.png" alt="Logo"/></a>
     
        </div>
      </div>
</>
       
    );
};

export default Footer;

