// export default Todayspricevolume;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
import ConfirmationDialog_add from "../ConfirmationDialog_add.js";
import ConfirmationDialog_minus from "../ConfirmationDialog_minus.js";
import EditDialog_minus from "./EditDialog_minus.js";
import EditDialog_add from "./EditDialog_add.js";
// import './portfolio.css';
const Todayspricevolume = ({ initialUserData }) => {
  const [stocks, setStocks] = useState([]);
  const [currentPrices, setCurrentPrices] = useState({}); // To store current prices
  const [currentVolume, setCurrentVolume] = useState({}); // To store current prices
  const [updateData, setUpdateData] = useState(null);
  const [editDialogOpen_add, setEditDialogOpen_add] = useState(false);
  const [editDialogOpen_minus, setEditDialogOpen_minus] = useState(false);
  const symbolslc = localStorage.getItem('symbolstring');

  useEffect(() => {
    const fetchPublicUserData = async () => {
      try {
        const username = localStorage.getItem('unm');
        const res = await axios.get(Base_URL + "/portfolio/stocks", {
          params: { username: username }
        });
        setStocks(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };

    fetchPublicUserData();
  }, []);


  useEffect(() => {
    if (symbolslc) {
      fetchStockData();
    }
  }, [symbolslc]);


  const localLogoImagePath = "/images/companylogos/";

  const handleStockModal_minus = (stock) => {
    setUpdateData(stock);
    setEditDialogOpen_minus(true);
  };

 

  const closeEditDialog_minus = () => {
    setEditDialogOpen_minus(false);
    setUpdateData(null);
  };

  const fetchStockData = () => {

    const symbols0 = localStorage.getItem('symbolstring');
    let symbolsArray = [];
  
    if (symbols0) {
      symbolsArray = symbols0.split(','); // Convert string to array
    }
    // const url = Base_URL_TV+`/tvapi?symbols=[${symbols}]`;
    const url = `${Base_URL_TV}/tvapi?symbols=[${symbolsArray.join(',')}]`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log("Received data:", data);
        // Update state with fetched data
        const prices = data.reduce((acc, stock) => {
          if (!stock.error) {
            acc[stock.symbol] = stock.close_price;
               }
          return acc;
        }, {});
        setCurrentPrices(prices);
        const volumes = data.reduce((acc, stock) => {
          if (!stock.error) {
            acc[stock.symbol] = stock.volume;
       
               }
          return acc;
        }, {});
        setCurrentVolume(volumes);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };


  return (
    <>
   <div className="container">
   <p className="text-white fs-6 fw-bold font-family-sans-serif px-3">
                TODAY’S PRICE & VOLUME INFORMATION
                </p>

<div className="row h-25 w-100 scrollevent px-3">
<div className="text-white text-opacity-20 fs-6 fw-normal font-family-sans-serif col-10 m-0" style={{ backgroundColor: 'black', width: '100%', height: '270px',  overflowX: 'hidden' }}> 

            <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
            justifyContent: "flex-start",
            flexShrink: "0",
            width: "890px",
            position: "relative",
          }}
        >
 <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexShrink: "0",
              width: "325px",
              position: "relative",
            }}
          >
            <div
              style={{
                color: "#ffffff",
                textAlign: "left",
                fontFamily: "sans-serif,'Montserrat-SemiBold'",
                fontSize: "14px",
                lineHeight: "140%",
                letterSpacing: "-0.01em",
                fontWeight: "600",
                position: "relative",
              }}
            >
              Stock{" "}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexShrink: "0",
              width: "150px",
              position: "relative",
            }}
          >
            <div
              style={{
                color: "#ffffff",
                textAlign: "left",
                fontFamily: "sans-serif,'Montserrat-SemiBold'",
                fontSize: "14px",
                lineHeight: "140%",
                letterSpacing: "-0.01em",
                fontWeight: "600",
                position: "relative",
              }}
            >
              Daily Close Price{" "}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexShrink: "0",
              width: "100px",
              position: "relative",
            }}
          >
            <div
              style={{
                color: "#ffffff",
                textAlign: "left",
                fontFamily: "sans-serif,'Montserrat-SemiBold'",
                fontSize: "14px",
                lineHeight: "140%",
                letterSpacing: "-0.01em",
                fontWeight: "600",
                position: "relative",
                width: "100px",
              }}
            >
              Daily Volume{" "}
              </div>
        </div>  
        </div>
        {stocks.map(stock => (
        <><div key={stock.ordid}
            style={{
              background: "var(--light-theme-gray-light-grey, #dfe5ef)",
              flexShrink: "0",
              width: "607px",
              height: "1px",
              position: "relative",
            }}
          ></div><div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexShrink: "0",
              width: "607px",
              position: "relative",
            }}
          >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexShrink: "0",
                  width: "325px",
                  position: "relative",
                }}
              >
                <img
                  className="rectangle-154"
                  style={{
                    borderRadius: "8px",
                    flexShrink: "0",
                    width: "48px",
                    height: "48px",
                    position: "relative",
                    objectFit: "cover",
                  }}
                  src={stock.ctick ? `${localLogoImagePath}/${stock.ctick}.png` : '/images/companylogos/default-logo.png'}></img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flex: "1",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      color: "#ffffff",
                      textAlign: "left",
                      fontFamily: "sans-serif,'Montserrat-SemiBold'",
                      fontSize: "14px",
                      lineHeight: "140%",
                      letterSpacing: "-0.01em",
                      fontWeight: "600",
                      position: "relative",
                      alignSelf: "stretch",
                    }}
                  >
                    {stock.cname}
                  </div>
                  <div
                    style={{
                      color: "#ffffff",
                      textAlign: "left",
                      fontFamily: "sans-serif,'Montserrat-Regular'",
                      fontSize: "12px",
                      lineHeight: "140%",
                      fontWeight: "400",
                      position: "relative",
                      alignSelf: "stretch",
                    }}
                  >
                    {stock.ctick}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexShrink: "0",
                  width: "150px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    color: "#ffffff",
                    textAlign: "left",
                    fontFamily: "sans-serif,'Montserrat-Regular'",
                    fontSize: "14px",
                    lineHeight: "140%",
                    letterSpacing: "-0.02em",
                    fontWeight: "400",
                    position: "relative",
                  }}
                >
                  {currentPrices[stock.ctick] !== undefined ? currentPrices[stock.ctick].toFixed(2) : 'N/A'}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexShrink: "0",
                  width: "100px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    color: "#ffffff",
                    textAlign: "left",
                    fontFamily: "sans-serif,'Montserrat-Regular'",
                    fontSize: "14px",
                    lineHeight: "140%",
                    letterSpacing: "-0.02em",
                    fontWeight: "400",
                    position: "relative",
                  }}
                >
                  {currentVolume[stock.ctick] !== undefined ? currentVolume[stock.ctick] : 'N/A'}
                </div>
              </div>
            </div></>
              ))}
        </div>
      </div>
 </div>
    </>
  );
};

export default Todayspricevolume;
