// export default EditDialog;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  TextField
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { Close } from '@material-ui/icons';
import axios from "axios";
import { Base_URL } from '../../Components/BaseURL.js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useNavigate} from 'react-router-dom';
const ChangeEmailDialog = ({ open, onClose, stockData, onUpdate }) => {
  const [tphone, setTphone] = useState('');
  const [teml, setEml] = useState('');
  const [formDataCode, setformDataCode] = useState({
    InputNewEmail:"",
  });
  const [updateData, setUpdateData] = useState(null);
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [rgvalue, setRGValue] = useState('phone');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const userid = localStorage.getItem('userid');
  const username = localStorage.getItem('unm');
  useEffect(() => {
    setformDataCode(prevData => ({
      ...prevData,
    }));
  }, [formDataCode.InputQty, formDataCode.InputNewQty]);

  useEffect(() => {
    if (stockData) {
      setformDataCode({
        InputNewEmail:stockData.InputNewEmail,
      });
     const temail = stockData.InputNewEmail;
    if (temail) {
      var email = temail;
      let hide = email.split("@")[0].length - 2;
      var r = new RegExp(".{"+hide+"}@", "g");
      email = email.replace(r, "***@");
      setEml(email);
  } else {
      // Handle the case where tp is null
      console.error('Email is null or undefined');
      // You can set a default value or handle the error as needed
      setEml(null); // or any default value
  }
   

    }
  }, [stockData]);



  const handleSendCode = async () => {
    try {
  
      const res = await axios.post(Base_URL + `/apiRouter/updateemailacode`, {userid:userid,username:username, newmail : formDataCode.InputNewEmail});
      toast.info("Email changed successfully");
      navigate('/login');
    } catch (error) {
      toast.error("Error sending confirmation code. Please try again later.");
      console.error(error);
      return;
    }
  };

  return (
    <>
    <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title">
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Change Email</Typography>
        <div className="col-auto font-family-sans-serif text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}>
        <Close/>
        </IconButton></div>
      </DialogTitle>
      <DialogContent dividers>
        <ToastContainer />
            <form className="form-inline">
                <div className="row g-3 align-items-center">
                <div className="form-group row">
                  <div className="col-12">
                  <p className="text-black fs-6 fw-normal font-family-sans-serif">Enter new email</p>
                  </div>
                     </div>
                <div className="form-group row">
                  <div className="col-6">
       <input type=""  
         className="text-dark fw-light w-100 float-start text-start"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputNewEmail"
               name="InputNewEmail"
               class="form-control text-start"
               value={formDataCode.InputNewEmail}
  onChange={(e) => setformDataCode({ ...formDataCode, InputNewEmail: e.target.value })}
                 /> 
                  </div>
                  <h6 className="mt-1 mb-1 d-flex justify-content-end">
            <small>
          New code can be requested in 150 seconds.
          </small>
          </h6>
                </div>
              </div>

              <div className="col-auto font-family-sans-serif text-end">
                <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSendCode}>Submit</Button>
              </div>
            </form>
      </DialogContent>
    </Dialog>
              </>
  );
};

export default ChangeEmailDialog;
