// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Base_URL } from '../../Components/BaseURL.js';
// import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { toast } from 'react-toastify';
// import { chartColors } from './colors.js';

// const Linechart = ({ initialUserData }) => {
//   const [stocks, setStocks] = useState([]);
//   const [currentPrices, setCurrentPrices] = useState([]);
//   const symbolslc = localStorage.getItem('symbolstring');
//   const delay = ms => new Promise(res => setTimeout(res, ms));
//   useEffect(() => {
//     const fetchLineChartData = async () => {
//       try {
//         const username = localStorage.getItem('unm');
//         const res = await axios.get(Base_URL + '/portfolio/stocks', {
//           params: { username: username }
//         });
//         setStocks(res.data);
//       } catch (error) {
//         console.error(error);
//         toast.error('Failed to fetch data');
//       }
//     };

//     fetchLineChartData();
//   }, []);

//   useEffect(async () => {
//     if (symbolslc) {
//       await delay(1000);
//       fetchStockData();
//       // setTimeout(() => {fetchStockData();}, 3000);
//     }
//   }, [symbolslc]);

//   const fetchStockData = () => {
//   //  const symbols = stocks.map(stock => stock.ctick);
//   const symbols0 = localStorage.getItem('symbolstring');
//   let symbolsArray = [];

//   if (symbols0) {
//     symbolsArray = symbols0.split(','); // Convert string to array
//   }

//     // const url = `${Base_URL_TV}/tvapi?symbols=[${symbolsArray.join(',')}]`;
//   const url = `${Base_URL_TV}/tvapimmvt?symbols=[${symbolsArray.join(',')}]`;
//     fetch(url)
//       .then(response => response.json())
//       .then(data => {
//         console.log('Received tvapimmvt data:', data);
//         setCurrentPrices(data[0]);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   };

//   const labels = ["3 Months ago", "Month ago", "Week ago", "Today"];
// // const labels = ["3 Months ago gain (T-90 Days)", "Month ago gain (T-30 Days)", "Week ago gain (T-7 Days)", "Today gain"];

//   const getDataPoints = (label, stock, matchingData) => {
//     const quantity = stock.csemco_quantity;
//     const avgPrice = stock.avgprice;

//     switch (label) {
//       case "3 Months ago":
//          return ((parseFloat(matchingData?.close_prices?.cp90days) - parseFloat(avgPrice)) * quantity).toFixed(2);
//       case "Month ago":
//          return ((parseFloat(matchingData?.close_prices?.cp30days) - parseFloat(avgPrice)) * quantity).toFixed(2);
//       case "Week ago":
//          return ((parseFloat(matchingData?.close_prices?.cp7days) - parseFloat(avgPrice)) * quantity).toFixed(2);
//       case "Today":
//          return ((parseFloat(matchingData?.close_prices?.cptoday) - parseFloat(avgPrice)) * quantity).toFixed(2);
//       default:
//         return 0;
//     }
//   };

//   const data = labels.map((label) => {
//     const dataPoint = { label };
//     stocks.forEach(stock => {
//       const matchingData = currentPrices.find(item => item.symbol === stock.ctick);
//       if (matchingData) {
//         dataPoint[stock.ctick] = getDataPoints(label, stock, matchingData);
//       }
//     });
//     return dataPoint;
//   });
//   const CustomTooltip = ({ active, payload, label }) => { 
//     if (active && payload && payload.length) {
//       const sortedPayload = [...payload].sort((a, b) => b.value - a.value);
//       return (

//         <div className="custom-tooltip" style={{backgroundColor: "#fff", width:'150px',border: "1px solid #ccc" }}>
//         <p className="label" style={{color: "#000000",fontFamily: 'sans-serif',fontWeight: "bold" }}>{label}</p>
//         {sortedPayload.map((entry, index) => (
//           <div key={`item-${index}`} style={{ display: 'flex'}}>
//             <span style={{ color: entry.color, fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>{entry.name}: </span>
//             <span style={{float:'right', color: "#000000", fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>
//                  {new Intl.NumberFormat('en-US', {
//                    style: 'decimal',
//                    maximumFractionDigits: 0,
//                   minimumFractionDigits: 0,
//                 }).format(entry.value)}
//                </span>
//           </div>
//         ))}
//       </div>



//         // <div className="custom-tooltip" style={{ backgroundColor: "#fff", border: "1px solid #ccc" }}>
//         //   <p className="label" style={{fontWeight: "bold" }}>{label}</p>
//         //   {sortedPayload.map((entry, index) => (
//         //     <div key={`item-${index}`} style={{ marginBottom: "0px" }}>
//         //       <span style={{ color: entry.color, fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>{entry.name}: </span>
//         //       <span style={{float:'right', color: "#000000", fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>
//         //         {new Intl.NumberFormat('en-US', {
//         //           style: 'decimal',
//         //           maximumFractionDigits: 0,
//         //           minimumFractionDigits: 0,
//         //         }).format(entry.value)}
//         //       </span>
//         //     </div>
//         //   ))}
//         // </div>
//       );
//     }
//     return null;
//   };
    
//   return (
//     <>
//    <div><p className="text-white fs-6 fw-bold font-family-sans-serif">Gain (LKR)</p></div> 
//     <ResponsiveContainer width="100%" height={350}>
//       <LineChart data={data}>
//         {/* <CartesianGrid strokeDasharray="3 3" /> */}
//         <CartesianGrid strokeDasharray="3 3" opacity={0.14} />
//         <XAxis dataKey="label" interval={0} angle={0} padding={{ left: 30, right: 30 }} textAnchor="middle" dx={-5} dy={5}
//           label={{
//             fontFamily: 'sans-serif',
//             fontSize: '0.65rem',
//           }} />
//         <YAxis
//   padding={{ top: 28 }}
//   interval={0}
//   tickSize={2}
//   tickFormatter={(value) => new Intl.NumberFormat().format(value)}
//   label={{
//     value: 'LKR',
//     position: { x: 40, y: 0 },
//     className: 'chart__label',
//     fontFamily: 'sans-serif',
//     fontSize: '0.65rem',
//   }}
// />
//        <Tooltip content={<CustomTooltip />}/>
//         <Legend layout="horizontal" verticalAlign="top" align="center" wrapperStyle={{ fontFamily: 'sans-serif',fontSize: '0.65rem'}} />
//         {stocks.map((stock, index) => (
//           <Line
//             key={stock.ctick}
//             dataKey={stock.ctick}
//             name={stock.ctick}
//             type="monotone"
//             stroke={chartColors[index % chartColors.length]}
//             dot={false} // Disable dots on the line
//           />
//         ))}
//       </LineChart>
//     </ResponsiveContainer>
//     </>
//   );
// };

// export default Linechart;


import { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { toast } from 'react-toastify';
import { chartColors } from './colors.js';

const Linechart = ({ initialUserData }) => {
  const [stocks, setStocks] = useState([]);
  const [currentPrices, setCurrentPrices] = useState([]);
  const symbolslc = localStorage.getItem('symbolstring');
  useEffect(() => {
    const fetchLineChartData = async () => {
      try {
        const username = localStorage.getItem('unm');
        const res = await axios.get(Base_URL + '/portfolio/stocks', {
          params: { username: username }
        });
        setStocks(res.data);
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch data');
      }
    };

    fetchLineChartData();
  }, []);

  useEffect(() => {
    if (symbolslc) {
      fetchStockData();
    }
  }, [symbolslc]);

  const fetchStockData = () => {
  //  const symbols = stocks.map(stock => stock.ctick);
  const symbols0 = localStorage.getItem('symbolstring');
  let symbolsArray = [];

  if (symbols0) {
    symbolsArray = symbols0.split(','); // Convert string to array
  }
    const url = `${Base_URL_TV}/tvapimmvt?symbols=[${symbolsArray.join(',')}]`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log('Received tvapimmvt data:', data);
        setCurrentPrices(data[0]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const labels = ["3 Months ago", "Month ago", "Week ago", "Today"];
 const getDataPoints = (label, stock, matchingData) => {
    const quantity = stock.csemco_quantity;
    const avgPrice = stock.avgprice;

    switch (label) {
      case "3 Months ago":
         return ((parseFloat(matchingData?.close_prices?.cp90days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Month ago":
         return ((parseFloat(matchingData?.close_prices?.cp30days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Week ago":
         return ((parseFloat(matchingData?.close_prices?.cp7days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Today":
         return ((parseFloat(matchingData?.close_prices?.cptoday) - parseFloat(avgPrice)) * quantity).toFixed(2);
      default:
        return 0;
    }
  };

  const data = labels.map((label) => {
    const dataPoint = { label };
    stocks.forEach(stock => {
      const matchingData = currentPrices.find(item => item.symbol === stock.ctick);
      if (matchingData) {
        dataPoint[stock.ctick] = getDataPoints(label, stock, matchingData);
      }
    });
    return dataPoint;
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const sortedPayload = [...payload].sort((a, b) => b.value - a.value);
      return (
        <>
           <div className="custom-tooltip" style={{backgroundColor: "#fff", width:'150px',border: "1px solid #ccc" }}>
        <p className="label" style={{color: "#000000",fontFamily: 'sans-serif',fontWeight: "bold" }}>{label}</p>
        {sortedPayload.map((entry, index) => (
          <div key={`item-${index}`} style={{ display: 'flex'}}>
            <span style={{ color: entry.color, fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>{entry.name}: </span>
            <span style={{float:'right', color: "#000000", fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>
                 {new Intl.NumberFormat('en-US', {
                   style: 'decimal',
                   maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(entry.value)}
               </span>
          </div>
        ))}
      </div>
      </>
      );
    }
    return null;
  };

   
  return (
    <>
<div><p className="text-white fs-6 fw-bold font-family-sans-serif">Gain (LKR)</p></div> 
     <ResponsiveContainer width="100%" height={300}>
      {/* aspect={3} */}
      <LineChart data={data}>
         <CartesianGrid strokeDasharray="3 3" opacity={0.14} />
       <XAxis dataKey="label" interval={0} angle={0} padding={{ left: 30, right: 30 }} textAnchor="middle" dx={-5} dy={5}
          label={{
            fontFamily: 'sans-serif',
            fontSize: '0.65rem',
          }} />
 <YAxis
  padding={{ top: 28 }}
  interval={0}
  tickSize={2}
  scale="auto"
  domain={["auto", "auto"]}
 // domain={[-1000, 'dataMax + 100']} // Example range; adjust based on your data
  tickFormatter={(value) => 
    //new Intl.NumberFormat().format(value)}
      new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(value)}
  label={{
    value: 'LKR',
    
    position: { x: 40, y: 18 },
    className: 'chart__label',
    fontFamily: 'sans-serif',
    fontSize: '0.60rem',
  }}
/>
        <Tooltip content={<CustomTooltip />}/>
         <Legend layout="horizontal" verticalAlign="top" align="center" wrapperStyle={{ fontFamily: 'sans-serif',fontSize: '0.65rem'}} />
         {stocks.map((stock, index) => (
           <Line
             key={stock.ctick}
             dataKey={stock.ctick}
             name={stock.ctick}
             type="monotone"
             stroke={chartColors[index % chartColors.length]}
             dot={false} // Disable dots on the line
           />
         ))}
       </LineChart>
     </ResponsiveContainer>
    </>
  );
};

export default Linechart;