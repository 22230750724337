// export default EditDialog;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  TextField
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { Close } from '@material-ui/icons';
import axios from "axios";
import { Base_URL } from '../../Components/BaseURL.js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';                                          
import { blueGrey } from '@mui/material/colors';
const EditDialogPW = ({ open, onClose, stockData, onUpdate }) => {
  const [tphone, setTphone] = useState('');
  const primary = blueGrey[700]; // #f44336
  const [teml, setEml] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [formDataStocks, setFormDataStocks] = useState({
    InputEmail:"",
    InputPWD: "",
    InputMobile:"",
  });
  const [updateData, setUpdateData] = useState(null);
  const [value, setValue] = useState();
  const [rgvalue, setRGValue] = useState('phone');
  const [showCodeInput, setShowCodeInput] = useState(false);

  useEffect(() => {
    setFormDataStocks(prevData => ({
      ...prevData,
    }));
  }, [formDataStocks.InputQty, formDataStocks.InputNewQty]);

  useEffect(() => {
    if (stockData) {
      setFormDataStocks({
        InputEmail:stockData.InputEmail,
        InputPWD: stockData.InputPassword,
        InputMobile: stockData.InputPhone,
      });
      // const tp = stockData.InputPhone;
      // const tempbobileno = '+94**'+tp.replace(/(?<!\()\d(?!\d?$)/g, '*');
      // setTphone(tempbobileno);
      // const temail = stockData.InputEmail;
      // var email = temail;
      // let hide = email.split("@")[0].length - 2;
      // var r = new RegExp(".{"+hide+"}@", "g");
      // email = email.replace(r, "***@");
      // setEml(email);
      const tp = stockData.InputPhone;
      if (tp) {
          const tempbobileno = '+94**' + tp.replace(/(?<!\()\d(?!\d?$)/g, '*');
          setTphone(tempbobileno);
      } else {
          // Handle the case where tp is null
          console.error('InputPhone is null or undefined');
          // You can set a default value or handle the error as needed
          setTphone(null); // or any default value
      }
      const temail = stockData.InputEmail;
      if (temail) {
        var email = temail;
        let hide = email.split("@")[0].length - 2;
        var r = new RegExp(".{"+hide+"}@", "g");
        email = email.replace(r, "***@");
        setEml(email);
    } else {
        // Handle the case where tp is null
        console.error('Email is null or undefined');
        // You can set a default value or handle the error as needed
        setEml(null); // or any default value
    }
    }
  }, [stockData]);

  const handleChangeRG = (event) => {
    setRGValue(event.target.value);
  };

  const handleSendCode = async () => {
    try {
      const response = await axios.post(Base_URL + `/apiRouter/forgotPassword`, { email : formDataStocks.InputEmail });
      toast.success("Password reset link sent. Please check your email inbox");
      onClose();
      return;
      //setShowCodeInput(true);
    } catch (error) {
      toast.error("Error sending confirmation code. Please try again later.");
      console.error(error);
      return;
    }
  };
  return (
    <>
    <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title">
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Change Password</Typography>
        <div className="col-auto font-family-sans-serif text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}s>
        <Close/>
        </IconButton></div>
      </DialogTitle>
      <DialogContent dividers>
        <ToastContainer />
     
            <form className="form-inline">
              <input type="hidden" name="InputEmail" id="InputEmail" value={formDataStocks.InputEmail}></input>
              <input type="hidden" name="InputPhone" id="InputPhone" value={formDataStocks.InputPhone}></input>
              <div className="row g-3 align-items-center" style={{backgroundColor: '#ffffff'}}>
                <div className="form-group row">
                  <div className="col-12">
                    <p className="text-black fs-6 fw-normal font-family-sans-serif">For your security, we will send an email on how to create a password. Click the link in the email to set up the password.</p>
                  </div>
                          </div>
                 </div>
                 
              <div className="p-4"></div>
              <div className="col-auto font-family-sans-serif text-end" style={{backgroundColor: '#ffffff'}}>
                <Button variant="contained" color="primary" onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="secondary" onClick={handleSendCode}>Send Link</Button>
              </div>
            </form>
          </DialogContent>
    </Dialog>
    </>
  );
};

export default EditDialogPW;
