import React, { useState, useEffect ,useContext } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext"; // Import useAuth
import "./login.css"; // Import specific CSS for Login
import { Base_URL } from "../../Components/BaseURL";
import { useWindowSize } from "@uidotdev/usehooks";
import {useNavigate} from 'react-router-dom';
import {ToastContainer,toast} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
function Forgotpw() {
  const [showPassword , setshowPassword ] = useState(false);
  const size = useWindowSize();
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login from context
 const [username, setUsername] = useState("");
  useEffect(() => {
   document.title = "stockwiseanalytics.com - Forgot password?"; //dinamically changes the tittle
 });
 useEffect(() => {
  // Apply styles on mount
  document.body.style.overflowX = 'hidden';
  document.body.style.overflowY = 'hidden';

  // Cleanup on unmount
  return () => {
    document.body.style.overflowX = '';
    document.body.style.overflowY = '';
  };
}, []);
const handleLogin = async (e) => {
  e.preventDefault();
  try {
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(username)) {
      toast.error("Error! you have entered invalid email.");
      return;
    } else {
      
    }
    if (!username || username === "") {
      toast.warning("Email Field is Required");
      return;
    }
    try {
      const response = await axios.post(Base_URL + `/apiRouter/forgotPassword`, { email : username });
      toast.success("Password reset link sent. Please check your email inbox");
         return;
      //setShowCodeInput(true);
    } catch (error) {
      toast.error("Error sending email. Please try again later.");
      console.error(error);
      return;
    }
  } catch (error) {
    console.error("Error Logging User: ", error);
    toast.error("Error Logging User");
  }
};

const navigateToLoginPage = () => {
    navigate('/userregister');
 }
 function handleClick() {
  navigate("/home");
}
  return (
    <>
    
  {/* <div className="container-fluid pt-0 mb-0 mt-0 bg-black text-white h-100">
      <div className="container mt-0"  style={{marginRight: '0px',paddingRight: '0px' }}>
        <div className="bg-black row centered-element">
          <div className="bg-black col-xxl-6"> */}
          <div className="container-fluid pt-0 mb-0 mt-0 bg-black text-white h-100">
<div className="mt-1" style={{marginRight: '0px'}}> 
        <div className="bg-black row centered-element">
          <div className="bg-black col-xxl-6">
          <img src="/images/fogetimg.png"  className="videoTag"/>
            </div>
            <div className="col-xxl-6 form-container d-flex justify-content-between viewport"           
          style={{paddingRight: '0px'}}>
          <div className="input-group mb-0 flex-column align-items-center min-vh-100" 
            style={{ top: '0px', textAlign: 'center', 
            width: '100%',
            background: '#000'
            // background: 'linear-gradient(to bottom right, black, #282727)',background: 'linear-gradient(to bottom, transparent 1%, black 100%)'
             }}>
              <div className="col-12 mt-5 justify-content-center align-items-center" style={{ textAlign: 'center' }}>
              
              <div className="justify-center items-center inline-flex pt-0">

  
</div>
<a href="/"><img className="img-fluid d-md-none" style={{ maxWidth: '45px' ,height:'auto'}} onClick={handleClick} src="/images/SVAlogo.png" alt="Logo" />
<img className="header-logo d-none d-md-block header-logo" onClick={handleClick}  src="/images/SVAlogoWithName.png" alt="Logo" />
      </a>  
              </div>
              <div className="container"></div>
              <form onSubmit={handleLogin} className='login-form justify-self' style={{width: '100%'}}>
                <div className="input-group mb-0">
                  <div className="error-setup text-nowrap"><p id='err' className='error-message'></p></div>
                </div>
                <div className="container mt-4"></div>

                <div className="LogIn w-100 h-5 text-center text-neutral-300 text-3xl fpw_heading">Forgot Password?</div>         
                <div class="p-5"></div>
                <div class="p-3"></div>
                <div className="w-100 h-5  mt-5 text-center text-neutral-300 text-3x mt-6"><span className="fpw_text1">Enter your </span><span className="fpw_text2">email address</span><span className="fpw_text1"> and we’ll send you a link to<br /> reset your password.</span></div>
                <div class="p-3"></div>

                <div className='mb-3'>
                <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'>
                <div style={{"color":"rgba(255, 255, 255, 0.49)","textAlign":"left","fontFamily":"'Montserrat-Regular', sans-serif","fontSize":"16px","lineHeight":"31px","fontWeight":"400","position":"relative","alignSelf":"stretch","flex":"1","display":"flex","alignItems":"center","justifyContent":"flex-start"}}>
                <input
      type="email"
      placeholder="Email Address"
      name="username"
      onChange={(evt) => setUsername(evt.target.value)}
      className="font-family-sans-serif emailctrl"
       style={{backgroundcolor: '#000000 !important'}}
       required
        />
     </div>
</div>
</div>


                {/* <div className='mb-3 d-grid gap-2 col-12 p-2 mx-auto googlecss justify-content-center align-items-center d-flex'>
                <div className="form-outline mb-0 border border-0">
                <input
      type="email"
      placeholder="Email Address"
      name="username"
      onChange={(evt) => setUsername(evt.target.value)}
      className="font-family-sans-serif emailctrl"
       style={{backgroundcolor: '#000000 !important'}}
       required
        />

  </div>
  </div> */}
  <div class="p-4"></div>

               <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'>
                <div className="form-outline mb-0 border border-0">
                        <div className='row justify-content-center align-items-center d-flex signupp'>
                        <button type="submit" className="text-nowrap send_btn">Send Email</button>
                        </div>
                        </div>
                    </div>
                    <div className="p-2"></div>
                    <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'>
                <div className="form-outline mb-0 border border-0">
                        <div className='row justify-content-center align-items-center d-flex signupp'>
                        <button type="button" onClick={navigateToLoginPage}  className="text-nowrap send_btn">Back</button>
                        </div>
                        </div>
                    </div>
                 <div className="p-2"></div>
           
                <div className="pb-2"></div>
                <div className="pb-2"></div>
                <div className="pb-2"></div>
                <div className="pb-2"></div>

                    </form>
            </div>
          </div>
        </div>
        
      </div>
      
    </div>
           <ToastContainer />
           <Browser size={size} />
                        </>
  );
}

export default Forgotpw;



