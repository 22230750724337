
import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base_URL } from '../../../Components/BaseURL.js';
import {
  PieChart, Pie, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import '../Portfolio.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import fetchCPrice from '../fetchCurrentPrice';
import html2canvas from 'html2canvas';
import { chartColors } from "../colors.js";
import { FadeLoader } from 'react-spinners';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import moment from 'moment';
import { calcDate } from '../../calcDate';
import ImageDisplay from '../../ImageDisplayprofile';
import PortfolioCreation from '../PortfolioCreation';
import Todayspricevolume from '../Todayspricevolume';
import Linechart from '../linechart';
import Finacialinfo from '../FinancialInfo';
import CSENEWS from '../CSENews.js';
import DividentTracker from '../DividentTracker';
import Marketsentiment from '../MarketSentiment';
import researchinfo from '../ResearchInfo';
import CurrentPortfolio from '../CurrentPortfolio';
import Button from '@mui/material/Button';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TwitterShareButton } from "react-share";
import domtoimage from "dom-to-image";
import { BaseURL_twitter } from '../../../Components/BaseURL_twitter.js';
import FetchDivPShare from "../fetchDivPShare";
const CombinedPortfolio = ({ label }) => {
  const [stocks, setStocks] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalpfsize, setTotalpfsize] = useState(0);
  const [totalqty, setTotalqty] = useState(0);
  const [Tots, setTots] = useState([]);
  const navigate = useNavigate();
  const [pfcompStrings, setPfcompStrings] = useState([]);
  const [gainStrings, setGainStrings] = useState([]);
  const chartRef = useRef(null);
  const backgColor = chartColors;  // Ensure this is properly defined
  const [totalGain, setTotalGain] = useState(0);  // Corrected naming
  const [isLoading, setIsLoading] = useState(true);
  const crdate = localStorage.getItem('crdate');
  const today = new Date();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const location = useLocation();
  // Formatting dates
  const formattedToday = moment(today).format('YYYY-MM-DD');
  const formattedCrDate = moment(crdate).format('YYYY-MM-DD');
  const dateDifference = calcDate(formattedToday, formattedCrDate);
  const [chartCenter, setChartCenter] = useState({ x: 0, y: 0 }); // Defined properly
  const username = localStorage.getItem('unm');
  const userid = localStorage.getItem('userid');
  const [count, setCount] = useState(0);
  const [currentPageName, setCurrentPageName] = useState('');
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [divpershare, setDivPershare] = useState([]);
  const [dividendData, setDividendData] = useState({});
  useEffect(() => {
    document.title = "stockwiseanalytics.com - Portfolio"; // Dynamically changes the title
  }, []);


/////////////
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  
    const updateWindowSize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateWindowSize);
  
      return () => {
        window.removeEventListener('resize', updateWindowSize);
      };
    }, []);
/////////////  

// Fetch portfolio data
useEffect(() => {
  const fetchPFData = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(Base_URL + "/portfolio/stocks", {
        params: { username: username }
      });
      setStocks(res.data);
      const symbols = res.data.map(stock => stock.ctick);
      localStorage.setItem('symbolstring', symbols); // Store symbols for further use
       } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
    }
  };
  fetchPFData();
}, []);






const fetchDividendData = useCallback(async () => {
  try {
    const DivPShareData = await FetchDivPShare();
    const dividendDataMap = DivPShareData.reduce((acc, data) => {
      acc[data.stock_name] = parseFloat(data.dividend_per_share);
      return acc;
    }, {});
    setDividendData(dividendDataMap);
  } catch (error) {
    console.error('Error fetching dividend data:', error);
  }
}, []);

useEffect(() => {
  fetchDividendData();
}, [fetchDividendData]);
  const calculateTotal = useCallback(async () => {
    try {
      let totalValue = 0;
      let tqty = 0;
      let totalPFSizeValue = 0;
      let labelsSet = new Set();
      let calculatedTots = [];
      let newGainStrings = [];
      let newPfcompStrings = [];
      let dnutpcnt = 0;

      for (let i = 0; i < stocks.length; i++) {
        const stock = stocks[i];
        const label = stock.cname;
      
        if (!labelsSet.has(label)) {
          labelsSet.add(label);
          const quantity = stock.csemco_quantity;
          const tick = stock.ctick;
          const dividendPerShare = dividendData[tick] || 0;

          const gain = dividendPerShare * quantity;
          const gainString = gain.toFixed(2);
          totalValue += Math.abs(gain);
          calculatedTots.push(gain.toFixed(2));
          newGainStrings.push(Math.round(gainString));
          
          const pfcomp = parseFloat(stock.avgprice) * quantity;
          const pfcompString = Math.round(pfcomp);
          tqty += quantity;
          totalPFSizeValue += pfcomp;
          newPfcompStrings.push(pfcompString);
        }
      }
      console.log('---totalPFSizeValue--'+totalPFSizeValue);

      setTotalpfsize(totalPFSizeValue);
      setTotalGain(totalPFSizeValue);
      setTotal(totalValue);   
      setPfcompStrings(newPfcompStrings);
      setTots(newGainStrings);
      setTotalqty(dnutpcnt);
    } catch (error) {
      console.error('Error calculating total:', error);
    }
  }, [stocks, dividendData]);

  

  useEffect(() => {
    if (stocks.length > 0) {
      calculateTotal();
      //setIsLoading(false);
    }
  }, [stocks, calculateTotal]);
  useEffect(() => {
    if (totalGain> 0) {
      setIsLoading(false);
    }
  }, [stocks, totalGain]);
  const chartData = stocks.map((stock, index) => ({
    name: stock.cname,
    value: stock.csemco_quantity * (dividendData[stock.ctick] || 0),
    tick: stock.ctick,
    color: backgColor[index]
  }));

  useEffect(() => {
    const updateChartCenter = () => {
      if (chartRef.current && typeof chartRef.current.getBoundingClientRect === 'function') {
        const chartBoundingBox = chartRef.current.getBoundingClientRect();
        const centerX = chartBoundingBox.width / 2;
        const centerY = chartBoundingBox.height / 2;
        setChartCenter({ x: centerX, y: centerY });
      }
    };
  
    updateChartCenter();
    window.addEventListener('resize', updateChartCenter);
    return () => window.removeEventListener('resize', updateChartCenter);
  }, []);
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: 'rgba(256,256,256, 0.3)',
        backgroundColor: 'rgba(256,256,256, 0.3)',
        height: 1,
      }}
    />
  );
  ////////
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function navigateTo(path) {
    navigate(path);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  function handleSelect_apd(evt) {
    navigateTo("/portfolio_apd");
  }

  function handleSelect_g_t(evt) {
    navigateTo("/portfolio_gain_today");
  }

  function handleSelect_g_lm(evt) {
    navigateTo("/portfolio_gain_last-month");
  }

  function handleSelect_g_ltm(evt) {
    navigateTo("/portfolio_gain_last-three-months");
  }
  const handleShare = () => {
    let node = document.getElementById('Doughnutrefid');
    domtoimage.toPng(node)
      .then(dataUrl => {
        axios.post(`${BaseURL_twitter}`, { dataUrl: dataUrl })
          .then(res => {
            const url = res.data.imageUrl;
            console.log('---url---',url);
            const twitterURL = `https://twitter.com/share?url=${url}&text=Check%20out%20this%20image&hashtags=yourHashtags`;
            window.open(twitterURL, "twitter");
          })
          .catch(err => console.log(err, "Error trying to tweet"));
      })
      .catch(err => console.log(err));
  };
  const downloadChartAsImage = async () => {
    const datapdf = document.getElementById('Doughnutrefid');
    const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    try {
      html2canvas(datapdf, { backgroundColor: 'black', allowTaint: true, useCORS: true }).then((canvas) => {
        var link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        // link.download = `portfolio_gainsnapshot_${currentDate}.png`;
        link.download = `portfolio_Annual_Projected_Dividend_${currentDate}.png`;
        link.click();
      });
    } catch (error) {
      console.error('Error downloading the chart:', error);
      toast.error('Failed to download chart');
    }
  };
  
  const handleMenuClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  return (
    <>
   <div className="p-4"></div>
 <div className="container-fluid">
<div className="row align-items-start bg-black  text-white centered-text-profilesettings"  style={{
           backgroundblendmode: 'exclusion',
           background: 'url(/images/Rectangle-2.png) lightgray 50% / cover no-repeat' ,
        width: `100`,
      }}>
<div className="p-4"></div><div className="p-4"></div><div className="p-4"></div><div className="p-2"></div>
<div className="row">
<div className="col-md-3">
    <div className="col" id="CSEMaverick">        
    <table className="table-borderless">  
          {/* <tr>
        <td className="d-flex justify-content-start" colSpan="2"  id="CSEMaverick">CSEMaverick</td>
        </tr> */}
        <tr>
        <td  colSpan="2"></td>&nbsp;</tr>
       <tr>      
        <td className="d-flex justify-content-start border-0">
        <ImageDisplay imageId={userid}/>
        </td>
        <td className="justify-content-start  text-nowrap">
        <div className="row">
        <div className="online"><img className="rounded-1 span4 center-block" src="/images/profile/vgeenclock.png"/><div className="ps-4">Online</div></div>
      </div>
      <div className="row">                                            
        <div className="joined-2-years-ago"><img className="rounded-1 span4 center-block" src="/images/profile/vbag.png"/>
        <div className="ps-4">Joined {!dateDifference.result ? "today" : dateDifference.result+" ago"}</div>
        {/* {dateDifference.result} ago */}        
        </div>
      </div>
        </td>
    </tr>

</table>
</div>
</div>
<div className="col-md-6">
<div className="col text-center">
<div className="pfcss ps-2">
<span><span className="pfcss-span">Track your investments, assess risk</span>
<span className="pfcss-span2"> and</span>
<span className="pfcss-span"> make informed decisions </span>
<span className="pfcss-span2">with our portfolio analysis tools. </span>
<span className="pfcss-span">Visualize your portfolio's performance </span>
<span className="pfcss-span2">with intuitive charts and metrics, empowering you to </span>
<span className="pfcss-span">optimize your investments </span>
<span className="pfcss-span2">and </span>
<span className="pfcss-span">achieve your financial goals with confidence. </span></span>
</div>
   </div>
  </div>
  <div className="col-md-3"></div>
    </div>
    <div className="p-4"></div>  
    <div className="p-4"></div>   
    <div className="p-4"></div>  
    <div className="col">   
    </div>
  </div>
  </div>
  
  <div class="container-fluid px-4">
  <div className="row">
  <p className="fininfo_title text-white fs-6 fw-bold font-family-sans-serif">            
    PORTFOLIO PERFORMANCE
      </p>
      </div>
      <div className="row">
      <div className="col-md-2 p-0"></div>
          <div className="col-md-8 p-0">
          <Breadcrumbs aria-label="breadcrumb" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700', 
                    fontFamily: 'sans-serif',
                    position: 'realative', 
                    left: '1%',
                    bottom:'2%',
                    zIndex: 'tooltip', //tooltip:1500 
                }} >
                        <Link underline="hover" color="white" href="/" style={{ fontFamily: 'Montserrat !important'}}>
            Home</Link>
        <Link underline="hover" color="white" href="/portfolio" style={{ fontFamily: 'Montserrat !important', fontSize: '0.8rem'}}>
        Portfolio Analytics</Link>
          <Typography color="white" style={{ fontFamily: 'Montserrat !important', fontSize: '0.8rem'}}>Annual Projected Dividend</Typography>
   </Breadcrumbs>
          </div>
          <div className="col-md-2 p-0"></div>
        </div>
        <div className="row">
    <div className="col-md-2 p-0"></div>
        <div className="col-md-8 p-0">
      <div className="col-md-8 p-3 d-grid gap-2 d-md-flex d-flex justify-content-center  rounded-top" style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)',width: '100%'}}>
        <Button
          className="btn me-md-2 basic-button text-capitalize font-Montserrat fs-6 portfoliobtn btn-sq w-100"
          onClick={handleMenuClick}
               >
         Annual Projected Dividend<ArrowDropDown />
        </Button>

        <Menu
          id="basic-menu"
          className="btn portfoliobtn"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              navigateTo("/portfolio");
              handleClose();
            }}
            className="menu-item portfoliobtnsel"
          >
            Gain snapshot
          </MenuItem>
          <MenuItem
            onClick={handleSelect_apd}
            className="menu-item portfoliobtnsel"
          >
            Gain
          </MenuItem>
        </Menu>

        <Button
          className="btn portfoliobtn me-md-2 basic-button text-capitalize font-Montserrat fs-6  btn-sq w-100"
          disabled
          style={{ fontFamily: 'Montserrat' }}
        >
          Till Date&nbsp;&nbsp;&nbsp;<ArrowDropDown />
        </Button>

        {/* <TwitterShareButton
          url={"https://www.stockwiseanalytics.com/"}
          title="Hey, I just shared my stocks"
          hashtags={["", ""]}
          onClick={handleShare}
          className="font-family-sans-serif  me-md-2"
        >
          <i className="btn portfoliobtn bi bi-twitter-x font-family-sans-serif fs-6" style={{ fontStyle: 'normal' }}>
            &nbsp;&nbsp;&nbsp;Share
          </i>
        </TwitterShareButton> */}
        <button className="btn portfoliobtn bi bi-download font-family-sans-serif fs-6 btn-sq w-100  css-1ujsas3" type="button" onClick={downloadChartAsImage}>
          &nbsp;&nbsp;&nbsp;Download
        </button>
      </div>
</div>
<div className="col-md-2 p-0"></div>
</div>

        <div className="row" id="Doughnutrefid">
        <div className="col-md-2 p-0"></div>
         <div className="col-md-4 p-0">
            <div ref={chartRef} style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)', height: "325px", position: "relative", marginBottom: "0%", padding: "0%" }} className="container d-flex align-items-center justify-content-center p-0 text-white font-family-sans-serif fontSize='12px'">
              {isLoading && (
                <div className="spinner-container">
                  <FadeLoader color="#ffffff" loading={isLoading} size={50} />
                </div>
              )}
<div style={{ width: '100%', height: 'auto', position: 'relative' }}>
<ResponsiveContainer width="100%" height={400}>
<PieChart ref={chartRef}>
  <Pie
    data={chartData}
    cx="50%"
    cy="50%"
    fontSize="12px"
    innerRadius={90}
    outerRadius={120}
    fill="#8884d8"
    dataKey="value"
    label={({ name, value }) => `${value.toFixed(2)} LKR`}
  >
    {chartData.map((entry, index) => (
      <Cell
        key={`cell-${index}`}
        fill={backgColor[index % backgColor.length]}
      />
    ))}
  </Pie>

  <Tooltip
    wrapperStyle={{ fontFamily: 'sans-serif', fontSize: '12px' }}
    content={({ active, payload }) => {
      if (active && payload && payload.length) {
        const { name, value } = payload[0];
        return (
          <div style={{ backgroundColor: 'white', fontFamily: 'Montserrat' ,padding: '5px', borderRadius: '5px' }}>
            {/* <p style={{ margin: 0 ,fontFamily: 'Montserrat' }}>{`${name}: ${value.toFixed(2)} LKR`}</p> */}
            <p style={{ fontFamily: 'sans-serif',fontSize: '10px', color: '#000000',fontWeight: 'bold'}}>{`${name}`}</p>
            <p style={{ fontFamily: 'sans-serif',fontSize: '10px', color: '#000000' }}>{`${value.toFixed(2)} LKR`}</p>
          </div>
        );
      }
      return null;
    }}
  />

<text x="50%" y="43%" fontSize="18px" textAnchor="middle" style={{ textAlign: 'center', color: 'white', fontSize: '18px', fontFamily: 'sans-serif', fontWeight: 700, lineHeight: '22px', whiteSpace: 'pre-wrap',}} fill="white" dominantBaseline="middle">
    {totalGain !== 0 ? `${((parseInt(total) * 100) / parseInt(totalGain)).toFixed(2)}%` : '0%'}
  </text>
  
  <text x="50%" y="50%" fontSize="11px" style={{ color: 'white', fontsize: '11px', fontFamily: 'sans-serif', fontWeight: 300, lineHeight: '16px', whiteSpace: 'pre-wrap',}} textAnchor="middle" fill="white" dominantBaseline="middle">
  Projected Div. Yield
  </text>
  
  <text x="50%" y="57%" fontSize="14px" textAnchor="middle" style={{color: 'white', fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 500, lineHeight: '16px', whiteSpace: 'pre-wrap',}} fill="white" dominantBaseline="middle">
    {total.toFixed(2)} LKR
  </text>
</PieChart>
      </ResponsiveContainer>
</div>
            </div>
          </div> 
       <div className="col-md-4 d-flex align-items-center justify-content-center font-family-sans-serif" style={{ backgroundColor: 'rgba(217, 217, 217, 0.20)',height: '325px', overflow: 'auto' }}>
            <ul>
            <table className="font-family-sans-serif text-white" style={{ fontSize: '12px',fontFamily: 'Montserrat'}}>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center font-family-sans-serif">Stock</th>
                  <th className="text-center font-family-sans-serif">Projected Dividend (LKR)</th>
                </tr>
              </thead>
              <tbody>
              {stocks.map((stock, index) => (
                  <tr key={index}>
                    <td><li style={{ backgroundColor: backgColor[index], color: backgColor[index],fontFamily: 'sans-serif',fontSize:'20px', lineHeight: '14px', verticalAlign:'middle'}}></li></td>
                    <td style={{ fontFamily: 'sans-serif',color: '#ffffff' }} nowrap>{stock.cname}&nbsp;</td>
                    <td className="text-nowrap text-end" style={{ fontFamily: 'Montserrat' }} nowrap>&nbsp;{Tots[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ul>
          </div>
          <div className="col-md-2 p-0"></div>
        </div>        
      </div>
      <div className="p-4"></div>  
      <div className="p-4"></div>  
      <div className="p-4"></div>  
      <div className="p-2"></div> 
            <div className="container-fluid">
          <ColoredLine />
          <div className="row">
            <div className="col-md-8">
              <CurrentPortfolio />
            </div>
            <div className="col-md-4">
              <PortfolioCreation />
            </div>
          </div>
          <div className="p-4"></div>  
          <ColoredLine />
          <div className="row">
          <div className="col-md-6" style={{paddingRight: '0px',paddingLeft: '0px'}}>
              <Todayspricevolume />
            </div>
            <div className="col-md-6">
              <Linechart />
            </div>
          </div>
          <ColoredLine />
          <div className="p-4"></div>  
          <div className="row">
            <div className="col-md-12">
              <Finacialinfo />
            </div>
          </div>
          <ColoredLine />
          <div className="p-4"></div>  
          <div className="row">
            <div className="col-md-12">
              <CSENEWS />
            </div>
          </div>
          <ColoredLine />
          <div className="p-4"></div>  
          <div className="row">
            <div className="col-md-12">
              <researchinfo />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <DividentTracker />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Marketsentiment />
            </div>
          </div>
      </div>
     
    </>
  );
};

export default CombinedPortfolio;
